package interviews

import dev.fritz2.core.RenderContext
import dev.fritz2.core.storeOf
import database.Stage
import database.ingredients

fun RenderContext.prefermentInterviewPart2() {

    fun buttonAction() {
        val stageIngredientStore = PartialStageStore.map(Stage.ingredients())
        // In part 3, we use qty for either g or %, so no value makes sense. We'll set it to 0.0 here.
        val newIngredientList = gSelectedIngredients.current.map { it.copy( qty = 0.0 ) }
        stageIngredientStore.update(newIngredientList)
        gSelectedIngredients.update(emptyList())
    }

    val parms = storeOf(
        ModalFrameworkParameterPak(
            title = "What's in your preferment?",
            description = "Select the ingredients included in the preferment:",
            panelContent = RenderContext::ingredientSelector,
            buttonContent = ::buttonAction,
            buttonLabel = "Next",
        )
    )
    modalFramework(parms)

}
