package database

import dev.fritz2.core.storeOf
import kotlinx.coroutines.Job
import model.JsonStringPack
import utils.alert

object FormulaListService {
    val listStore = storeOf<List<String>>(emptyList(), job = Job())

}

actual fun selectFormula(formulas: List<String>) {
    FormulaListService.listStore.update(formulas)
}

actual fun selectFormulaError(message: String) {
    alert(message)
}

actual fun loadFormulaFromJson(jsp: JsonStringPack) {
    FormulaStore.loadJson(jsp)
    IngredientDatabaseStore.jsonLoad(jsp)
}