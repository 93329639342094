package database

import dev.fritz2.core.Id
import dev.fritz2.core.Lenses
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
enum class Units {
    @SerialName("g") G,
    @SerialName("kg") Kg,
    @SerialName("ml") Ml,
    @SerialName("l") L,
    @SerialName("tsp") Tsp,
    @SerialName("tbsp") Tbsp,
    @SerialName("cup") Cup,
    @SerialName("oz") Oz,
    @SerialName("lb") Lb,
}

// Use this to group ingredients by bread type
@Serializable
enum class IngredientGroup {
    @SerialName("lean")   Lean,       // For basic lean breads
    @SerialName("sweet")  Sweet,      // For sweet breads, muffins, etc.
    @SerialName("rich")   Rich,       // For rich breads, like brioche
    @SerialName("rye")    Rye,        // For rye breads
    @SerialName("milled") Milled,     // For fresh milled grains
    @SerialName("all")    All,        // For all ingredients
}

// Three ways to use Ingredient:
// isVol = true: volUnit and volume determine quantity; grams is not used
// isVol = false: grams determines quantity, volUnit and volume are not used.
// The ingredient database stores the conversion from volume to grams like this:
// volume and volUnit hold the volumetric measure; grams holds the gram conversion,
// and isVol is true.
@Lenses
@Serializable
data class Ingredient(
    // Fields stored in the database
    val id:                 Long = 0,           // Database primary key
    val name:               String = "",
    val isFlour:            Boolean = false,
    val units:              Units = Units.G,
    val conversion:         Double = 0.0,       // Conversion factor from one convUnit to grams
    val convUnit:           Units = Units.Cup,  // If user enters a different volume unit, convert to this one
    val group:              IngredientGroup = IngredientGroup.Lean,
    val ownerId:            Long? = null,       // The owner of this ingredient. Null means it's a system ingredient.
    // Fields not stored in the database, but kept in memory for use in the formula
    val key:                String = Id.next(), // Our unique key. Use this, not id, so there can be multiple ingredients with the same name
    val qty:                Double = 0.0,
    val percent:            Double = 0.0,
    val isRollup:           Boolean = false,    // If this is a rollup of another stage, it can't be used in overall formula
    val rollupStageKey:     String = "",        // In a rollup, this is the stage key of the stage that is rolled up
    val isUsed:             Boolean = false,    // Rollups can only be used one time
    val saveThis:           Boolean = false,    // If true, save this ingredient in json
    val usePercent:         Boolean = true,     // For preferment interview, the state of the percent / g switch
    val useAll:             Boolean = false,    // If true, use all of this ingredient in a preferment
    val useOnly:            Boolean = false,    // If true, use this much of ingredient in preferment and don't carry the rest to final. Intended for starter.
)
{
    companion object {}
}
