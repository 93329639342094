package main

import database.CustomerSession
import database.IngredientDatabaseStore
import database.IngredientGroup
import dev.fritz2.core.render
import dev.fritz2.headless.foundation.portalRoot
import io.github.jan.supabase.auth.auth
import kotlinx.browser.window
import kotlinx.coroutines.launch
import main_panel.formulaBlock
import model.UI
import org.w3c.dom.url.URLSearchParams
import routing.Router
import routing.buttonBlock
import session.HttpSession
import utils.myLog
import utils.myLogError


fun appMain() {

    IngredientDatabaseStore.load(IngredientGroup.All)

    if (Router.protectedRoute()) {
        render("#target") {
            div("body-fix overflow-auto") {
                div("bg-background") {
                    buttonBlock()
                }

                div("flex flex-col mx-auto items-center max-w-2xl w-full ${UI.menuTranslate}") {
                    h1("text-3xl font-bold text-headline") {
                        +"Baker's Formulary"
                    }

                    h2 {
                        +"v${UI.VERSION}"
                    }
                    // testGrid()
                    // loginScreen()
                    formulaBlock()
                    // saveBlock()
                    // displayViewportInfo()
                }
            }
            portalRoot() // This is required for open/close items, like menus and popOvers. It must be last.
        }
    }

}

fun cleanUrlAndRouteToWelcome() {
    val cleanUrl = "${window.location.protocol}//${window.location.host}/#WELCOME"
    window.history.replaceState(data = "", title = "", url = cleanUrl)
    myLog("cleanUrl: $cleanUrl")
    Router.routeTo(Router.Page.WELCOME)
    Router.start()
}

/*
 *  This function checks if the URL has an access_token. If it does, it imports the
 *  tokens into the session and returns true. Otherwise, it returns false.
 */
fun isServerRedirect(): Boolean {
    val hashParams = window.location.hash.drop(1)
    val urlParams = URLSearchParams(hashParams)
    val accessToken = urlParams.get("access_token")
    val refreshToken = urlParams.get("refresh_token")
    if (accessToken != null && refreshToken != null) {
        myLog("accessToken: ${accessToken.take(15)}...")
        myLog("refreshToken: ${refreshToken.take(15)}...")
        // This is a server redirect from a new user confirmation email.
        Router.coroutineScope.launch {
            val client = HttpSession.fetchClient()
            try {
                client.auth.importAuthToken(
                    accessToken = accessToken,
                    refreshToken = refreshToken
                )
            } catch (e: Exception) {
                myLogError("Error setting session: $e")
            }
        }
        return true
    }
    return false
}


fun main() {
    if (isServerRedirect()) {
        try {
            cleanUrlAndRouteToWelcome()
        } catch (e: Exception) {
            myLogError("Error in cleanUrlAndRouteToWelcome(): $e")
        }
    } else {
        myLog("Not a server redirect")
        // If user exists, we're already logged in, and we're here because of a page refresh.
        // In that case, load the customer. The protectedRoute function will check if the user is logged in, find that
        // they are, and proceed to the appMain function. So this is the place to re-load the customer.
        val user = HttpSession.fetchClient().auth.currentUserOrNull()
        if (user != null) {
            Router.coroutineScope.launch {
                CustomerSession.load(user)
                val c = CustomerSession.get()
                myLog("main: User ${c.first_name} id ${c.id} is logged in")
                Router.routeTo(page = Router.Page.APP)
                Router.start()
            }
        } else {
            myLog("main: User is not logged in")
            Router.routeTo(page = Router.Page.LOGIN)
            Router.start()
        }
    }
}



// http://localhost:8080/?code=19f1f1e6-2f11-4143-aee5-3c35111b988e

// http://localhost:8080/#access_token=eyJhbGciOiJIUzI1NiIsImtpZCI6IldSZHRvOTgrTkN0OG9MclAiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNzEzMjk5MTQ4LCJpYXQiOjE3MTMyOTU1NDgsImlzcyI6Imh0dHBzOi8vaXB2Y2ludXZzaGVkZnp1cnRzcWEuc3VwYWJhc2UuY28vYXV0aC92MSIsInN1YiI6IjY2MDZhM2E2LTNlZTItNGRiYi1iN2UyLTNhNjY5M2JlN2UyNSIsImVtYWlsIjoiam9uQGNoZWNrZXJib3guY29tIiwicGhvbmUiOiIiLCJhcHBfbWV0YWRhdGEiOnsicHJvdmlkZXIiOiJlbWFpbCIsInByb3ZpZGVycyI6WyJlbWFpbCJdfSwidXNlcl9tZXRhZGF0YSI6eyJiYWtpbmdfbGV2ZWwiOiJhZHZhbmNlZCIsImNpdHkiOiJsYSIsImVtYWlsIjoiam9uQGNoZWNrZXJib3guY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJzdF9uYW1lIjoiSm9uIiwibGFzdF9uYW1lIjoiTWFuZGVsIiwicGhvbmVfdmVyaWZpZWQiOmZhbHNlLCJzdWIiOiI2NjA2YTNhNi0zZWUyLTRkYmItYjdlMi0zYTY2OTNiZTdlMjUiLCJ5ZWFyc19iYWtpbmciOjE5fSwicm9sZSI6ImF1dGhlbnRpY2F0ZWQiLCJhYWwiOiJhYWwxIiwiYW1yIjpbeyJtZXRob2QiOiJvdHAiLCJ0aW1lc3RhbXAiOjE3MTMyOTU1NDh9XSwic2Vzc2lvbl9pZCI6IjBlZTlhMzYxLTI1NDItNDk5Mi1iMmE5LWY4NDk5YzVlN2I2MiIsImlzX2Fub255bW91cyI6ZmFsc2V9.WCWgzYAp6tdPmoR9-J8UL-9I8pAEI74pQeAbtD7LJug
// &expires_at=1713299148
// &expires_in=3600
// &refresh_token=KYjiw6taYhoASiru_cDaZg
// &token_type=bearer
// &type=signup