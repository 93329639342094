package model

import kotlinx.serialization.Serializable

@Serializable
data class FormulaParams(
    val title_key: String,
    val owner_id: Long,
    val formula: String,
    val rollups: String,
) {
    companion object
}