package interviews

// import app.FormulaStore.invoke
import database.*
import dev.fritz2.core.*
import dev.fritz2.headless.components.checkboxGroup
import dev.fritz2.headless.components.modal
import kotlinx.coroutines.Job
import org.w3c.dom.HTMLElement
import utils.myLog

object interviewUI {
    val modalPanel = "absolute top-0 left-0 w-full h-full bg-gray-400 bg-opacity-50 z-[25] flex flex-row justify-center items-center isolate"
    val modalOverlay = "absolute top-[30px] mx-auto min-w-[400px] max-w-[600px] bg-slate-100 shadow-lg mt-4 border border-slate-500 rounded-lg p-4 isolate z-[26]"
    val modalTitle = "flex flex-row font-semibold w-full justify-center text-center"
    val modalDescription = "flex flex-row w-full text-center"
    val buttonBlock = "flex flex-row justify-end gap-2 mt-4"
    val nextButton = "bg-lime-500 hover:bg-lime-600 text-white font-bold py-2 px-4 rounded"
    val cancelButton = "bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
    val labelFormat = "block uppercase text-gray-700 px-4 text-xs font-bold"
}

object PartialStageStore : RootStore<Stage>(Stage(prefermentedFlour = 0.2), job = Job()) {
    val finalize = handle {
        // This might be a good place to convert g entries to %
        FormulaStore.addStage(it)
        it
    }
    val reset = handle { Stage(ingredients = emptyList()) }
}

object PreFinalStageStore: RootStore<Stage>(Stage(), job = Job()) {
    val finalize = handle {
        FormulaStore.addStage(it)
        it
    }
    val reset = handle { Stage() }
}


data class ModalFrameworkParameterPak(
    val title: String,
    val description: String,
    val panelContent: RenderContext.() -> Unit = {},
    val buttonContent: () -> Unit = {},
    val buttonLabel: String = "Next",
)

fun Tag<HTMLElement>.myTransition() {
    return transition(
        "ease-out duration-300",
        "opacity-0",
        "opacity-100",
        "ease-in duration-200",
        "opacity-100",
        "opacity-0"
    )
}

/*
 *  States the interview can be in:
 *      0:  No interview / interview complete
 *      1:  Doing Preferment interview, part 1
 *      2:  Doing Preferment interview, part 2
 *      3:  Doing Preferment interview, part 3
 *      4:  Doing Preferment cleanup, which adds the completed state to the formula
 *     -1:  Cancelled
 *
 * Transitions between states and events that trigger them
 *      0 -> 1:  Click on "Add Preferment" button
 *      1 -> 2:  Click on "Next" button in part 1
 *      2 -> 3:  Click on "Next" button in part 2
 *      3 -> 4:  Click on "Next" button in part 3
 *      4 -> 0:  part 4 processing is complete
 *      1 -> -1:  Click on "Cancel" button in part 1
 *      2 -> -1:  Click on "Cancel" button in part 2
 *      3 -> -1:  Click on "Cancel" button in part 3
 *      4 -> -1:  Error in processing part 4
 */
object StateStore : RootStore<Int>(0, job = Job()) {
    val increment = handle { it + 1 }
    val reset = handle { 0 }
    val cancel = handle { -1 }
}

val gSelectedIngredients = storeOf(emptyList<Ingredient>(), job = Job())

fun RenderContext.ingredientSelector() {
    div {
        checkboxGroup(tag = RenderContext::fieldset) {
            value(gSelectedIngredients)

            PreFinalStageStore.data.render { final ->
                val allIngs = final.ingredients
                allIngs.forEach { ing ->
                    checkboxGroupOption(ing) {
                        checkboxGroupOptionToggle(tag = RenderContext::label) {
                            selected.render {
                                val spanTag = if (it) "icon-[grommet-icons--checkbox-selected] " else "icon-[grommet-icons--checkbox] "
                                p("flex flex-row w-[250px] h-[2em] p-3 items-center my-4" + if (it) " bg-lime-200" else " bg-white hover:bg-lime-100") {
                                    span(spanTag + "text-slate-700 text-2xl mr-2") {}
                                    +ing.name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.modalFramework(parms: Store<ModalFrameworkParameterPak>) {
    val tstate = storeOf(true)
    parms.data.render { p ->
        modal {
            openState(store = tstate)
            modalPanel(interviewUI.modalPanel) {
                modalOverlay(interviewUI.modalOverlay) {
                    myTransition()
                    modalTitle(interviewUI.modalTitle) { +p.title }
                    modalDescription(interviewUI.modalDescription) { +p.description }

                    p.panelContent(this)

                    div(interviewUI.buttonBlock) {
                        button(interviewUI.nextButton) {
                            +p.buttonLabel
                            setInitialFocus
                            clicks handledBy {
                                p.buttonContent()
                                StateStore.increment()
                                tstate.update(false)
                            }
                        }
                        button(interviewUI.cancelButton) {
                            +"Cancel"
                            clicks handledBy {
                                FormulaStore.clearStageRequest()
                                StateStore.reset()
                                tstate.update(false)
                            }
                        }
                    }
                }
            }
        }
    }

}

fun rollUpStageToIngredient(s: Stage): Ingredient {
    val midStage = s.ingredients
    val newName = s.name
    val rollup = Ingredient(
        name            = newName, // Not "All of xxx" unless it's in a soaker or we use it all up in a preferment or final
        qty             = midStage.sumOf { it.qty },
        isFlour         = false,
        units           = Units.G,
        isRollup        = true,
        rollupStageKey  = s.key,
        isUsed          = false,
        saveThis        = true,
    )
    myLog("rollUpStageToIngredient: Adding rollup ingredient ${rollup.name} to the ingredient database")


    // Log state before adding ingredient
    myLog("rollUpStageToIngredient: State before adding ingredient: ${IngredientDatabaseStore.current.ingredients.map { it.name }}")

    IngredientDatabaseStore.addIngredient(rollup)


    // Log state after adding ingredient
    myLog("rollUpStageToIngredient: State after adding ingredient: ${IngredientDatabaseStore.current.ingredients.map { it.name }}")

    return rollup
}







