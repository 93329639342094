package auth

import dev.fritz2.core.*
import dev.fritz2.headless.foundation.portalRoot
import dev.fritz2.validation.messages
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import model.*
import routing.Router
import utils.severityToTextColor

fun signupPage() {

    val userParms = Router.UserParamsStore
    val username = userParms.map(UserLoginParams.email())
    val password = userParms.map(UserLoginParams.password())

    // These keep the form from generating validation messages when the Captcha updates
    val passwordFocused = storeOf(false, job = Job())
    val emailFocused = storeOf(false, job = Job())

    val newCustomerParms = storeOf(NewCustomerData(), job = Job())
    val firstName = newCustomerParms.map(NewCustomerData.first_name())
    val lastName = newCustomerParms.map(NewCustomerData.last_name())
    val yearsBaking = newCustomerParms.map(NewCustomerData.years_baking())
    val bakingLevel = newCustomerParms.map(NewCustomerData.baking_level())
    val city = newCustomerParms.map(NewCustomerData.city())
    val accessCode = newCustomerParms.map(NewCustomerData.access_code())

    val inputStyle = "text-sm bg-gray-100 w-full p-2 rounded-lg border border-gray-500"
    val validationMessageStyle = "text-xs mt-1"

    setupCallback()

    render {
        div("max-w-xl mx-auto mt-12") {
            div("max-w px-8 py-1 bg-white shadow-lg rounded-lg") {
                // Version
                div("flex justify-end") {
                    p("text-right text-xs text-zinc-300") {
                        +UI.VERSION
                    }
                }
                // logo
                div("flex justify-center mt-4") {
                    img("w-[200px]") {
                        src("bf-logo-lg.png")
                    }
                }
                // Welcome message
                div("mt-2 w-full px-8 py-2") {
                    h1("text-2xl font-bold text-headline") {
                        +"Create your free account"
                    }
                }
                // Email address
                div("mt-2 w-full px-8 py-1") {
                    input(inputStyle) {
                        type("text")
                        placeholder("email")
                        value(username.data)
                        changes.values() handledBy username.update
                        domNode.onfocus = { emailFocused.update(true) }
                        domNode.onblur = { emailFocused.update(false) }
                    }
                    username.messages<SignUpMessage>()?.renderEach { message ->
                        if (emailFocused.current) {
                            val pStyle = validationMessageStyle + " " + severityToTextColor(message.severity)
                            p(pStyle) { +message.text }
                        }
                        else {
                            p {}
                        }
                    }
                }

                // Password
                div("w-full px-8 py-1 mt-4") {
                    input(inputStyle) {
                        type("password")
                        placeholder("Password")
                        value(password.data)
                        changes.values() handledBy password.update
                        domNode.onfocus = { passwordFocused.update(true) }
                        domNode.onblur = { passwordFocused.update(false) }
                    }
                    password.messages<SignUpMessage>()?.renderEach { message ->
                        if (passwordFocused.current) {
                            val pStyle = validationMessageStyle + " " + severityToTextColor(message.severity)
                            p(pStyle) { +message.text }
                        } else {
                            p {}
                        }
                    }
                }

                // First name
                div("w-full px-8 py-1 mt-4") {
                    input(inputStyle) {
                        type("text")
                        placeholder("First name")
                        value(firstName.data)
                        changes.values() handledBy firstName.update
                    }
                }
                // Last name
                div("w-full px-8 py-1 mt-4") {
                    input(inputStyle) {
                        type("text")
                        placeholder("Last name")
                        value(lastName.data)
                        changes.values() handledBy lastName.update
                    }
                }
                div("w-full grid grid-cols-2 mt-4 gap-0") {
                    // Years baking
                    div("w-full pl-8 pr-2 py-1") {
                        val vString: String = if (yearsBaking.current == 0) "" else yearsBaking.data.toString()
                        input(inputStyle) {
                            type("number")
                            placeholder("Years baking")
                            value(vString)
                            changes.values().map { it.toInt() } handledBy yearsBaking.update
                        }
                    }
                    // Baking level
                    div("w-full pl-2 pr-8 py-1") {
                        select(inputStyle) {
                            option {
                                +"Baking level"
                            }
                            BakingLevel.entries.forEach {
                                option {
                                    +it.name
                                }
                            }
                            value(bakingLevel.data.asString())
                            changes.values().map { BakingLevel.valueOf(it) } handledBy bakingLevel.update
                        }
                    }
                }
                // City
                div("w-full px-8 py-1 mt-4") {
                    input(inputStyle) {
                        type("text")
                        placeholder("City")
                        value(city.data)
                        changes.values() handledBy city.update
                    }
                }
                // Access code
                if (UI.REQUIRE_ACCESS_CODE) {
                    div("w-full px-8 py-1 mt-4") {
                        input(inputStyle) {
                            type("text")
                            placeholder("Access code")
                            value(accessCode.data)
                            changes.values() handledBy accessCode.update
                        }
                    }
                }

                // Captcha
                createCaptchaElement()

                div("flex-row justify-center mt-6 mb-4 w-full text-center") {
                    button("bg-lime-300 text-black font-bold p-4 rounded-lg px-8 justify-self-center cursor-pointer") {
                        +"Sign up"
                        clicks.map {
                            Pair(
                                userParms.current,
                                newCustomerParms.current
                            )
                        } handledBy Router.UserParamsStore.processSignup

                    }
                }
                div("flex-row justify-center mt-6 mb-4 w-full text-center") {
                    a("cursor-pointer") {
                        +"Already have an account? Log in instead."
                        clicks handledBy {
                            Router.routeTo(Router.Page.LOGIN)
                        }
                    }
                }
            }
        }
        portalRoot()
    }

}
