package database

import dev.fritz2.core.Handler
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import interviews.StateStore
import kotlinx.coroutines.Job
import model.JsonStringPack
import utils.alert
import utils.myLog


/*
 * The primary Formula store. There is only one.
 * This is initialized with emptyFormula(), not Formula(), because the interface needs a stage and an ingredient
 * to render properly. Once one ingredient is rendered, the user can add more by loading a Formula or adding individual
 * ingredients.
 */
object FormulaStore : RootStore<Formula>(Formula.emptyFormula(), job = Job()) {

    val addIngredient: Handler<Unit> = handle { f ->
        f.addIngredient()
    }

    val addStage: Handler<Stage> = handle { f, s ->
        f.addStage(s)
    }

    val calc: Handler<Unit> = handle { f ->
        f.recalc(false,)
    }

    val calcDispatch: Handler<String> = handle { f, variant ->
        when (variant) {
            "g" -> f.recalc(false,)
            "p" -> f.calcPct()
            "k" -> { alert("Kitchen measure conversions not implemented yet"); f }
            else -> f
        }
    }

    val clear: Handler<Unit> = handle { _ -> Formula.emptyFormula() }

    val clearStageRequest: Handler<Unit> = handle { f ->
        f.copy(newStageRequest = false)
    }

    val createFinalStage: Handler<Unit> = handle { f ->
        f.createFinalStage()
    }

    val deleteIngredient: Handler<Pair<String, String>> = handle { f, (stageKey, ingredientId) ->
        f.deleteIngredient(stageKey, ingredientId)
    }

    val deleteStage: Handler<String> = handle { f, stageKey ->
        f.deleteStage(stageKey)
    }

    val loadJson: Handler<JsonStringPack> = handle { f, jsp ->
        f.loadJson(jsp.formulaJson)
    }

    val renameStage: Handler<Pair<String, String>> = handle { f, p ->
        myLog("renameStage: $p")
        val (stageKey, newName) = p
        f.renameStage(stageKey, newName)
    }

    val replaceIngredient: Handler<Triple<String, String, String>> = handle { f, (stageKey, oldIngId, newIngName) ->
        f.replaceIngredient(stageKey, oldIngId, newIngName)
    }

    val requestStage: Handler<StageType> = handle { f, t ->
        myLog("FormulaStore.requestStage: $t")
        StateStore.reset()
        StateStore.increment()
        f.copy(newStageRequest = true, newStageType = t)
    }

    val updateNPieces: Handler<String> = handle { f, numPieces ->
        f.updateNPieces(numPieces.toDouble())
    }

    val updatePieceSize: Handler<String> = handle { f, pSize ->
        f.updatePieceSize(pSize.toDouble())
    }
}