package session

import io.github.jan.supabase.SupabaseClient
import io.github.jan.supabase.auth.Auth
import io.github.jan.supabase.auth.auth
import io.github.jan.supabase.createSupabaseClient
import io.github.jan.supabase.logging.LogLevel
import io.github.jan.supabase.postgrest.Postgrest
import io.github.jan.supabase.serializer.KotlinXSerializer
import kotlinx.serialization.json.Json
import model.UI.USE_DEV_DB
import utils.myLog


object HttpSession {

    const val AUTH_COOKIE_NAME = "bakersformulary_session_token"

    private val client: SupabaseClient by lazy {
        myLog("Creating SupabaseClient")
        val sUrl = if (USE_DEV_DB) "https://xeypgunsrpeoqiqvvxpr.supabase.co" else "https://oukjwygbyupfwdzjcidy.supabase.co"
        val sKey = if (USE_DEV_DB) "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhleXBndW5zcnBlb3FpcXZ2eHByIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTgwODI2NTksImV4cCI6MjAzMzY1ODY1OX0.VaZ4vOAMha2Fky4ZtIn_hE_P94kqdAnpD60D6Ou-DQM"
                            else "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im91a2p3eWdieXVwZndkempjaWR5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTkzNzMyMzMsImV4cCI6MjAzNDk0OTIzM30.JKdww6hNhETPq7pJLx1uybg1CzwsOT7_PaitiSGrzlI"
        myLog("HttpSession: connecting to sUrl = $sUrl with sKey = $sKey")

        // The Kotlin library uses the Supabase REST API. This API provides its own pooling mechnism, which is distinct
        // from the Supabase pooler, which provides transaction and session modes. AFAIK there is nothing I need
        // to do to manage the REST API pooler here.
        try {
            createSupabaseClient( supabaseUrl = sUrl, supabaseKey = sKey ) {
                defaultSerializer = KotlinXSerializer(
                    Json {
                        prettyPrint = true
                        isLenient = true
                        ignoreUnknownKeys = true
                        defaultLogLevel = LogLevel.ERROR
                    }
                )
                defaultLogLevel = LogLevel.ERROR

                install(Postgrest)
                install(Auth)
            }

        } catch (e: Exception) {
            myLog("Error creating HttpSession: ${e.message}")
            throw e
        }

    }

    fun fetchClient(): SupabaseClient {
        return client
    }

}

expect object CookieHandler {
    fun getCookie(name: String): String?
    fun setCookie(name: String, value: String, days: Int)
}

fun isLoggedIn(): Boolean {
    val sessionToken = CookieHandler.getCookie(HttpSession.AUTH_COOKIE_NAME)
    if (sessionToken == null) {
        return false
    }

    return try {
        val client = HttpSession.fetchClient()
        val currentUser = client.auth.currentUserOrNull()
        currentUser != null
    } catch (e: Exception) {
        myLog("Session validation failed: ${e.message}")
        false
    }
}
