package auth

import dev.fritz2.core.render
import dev.fritz2.core.src
import model.UI

fun signupSuccessPage() {
    render {
        div("max-w-xl mx-auto mt-12") {
            div("max-w px-8 py-1 bg-white shadow-lg rounded-lg") {
                // Version
                div("flex justify-end") {
                    p("text-right text-xs text-zinc-300") {
                        +UI.VERSION
                    }
                }
                // logo
                div("flex justify-center mt-4") {
                    img("w-[200px]") {
                        src("bf-logo-lg.png")
                    }
                }
                // Thank you message
                div("mt-2 w-full p-8 mt-10 flex flex-col items-center") {
                    h1("text-2xl font-bold text-emerald-800") {
                        +"Thank you for signing up."
                    }
                    p("mt-4 text-lg text-slate-800 font-semibold text-center") {
                        +"We've sent you an email with a link to verify your account. Please check your inbox and click the link to complete the signup process."
                    }
                }

            }
        }
    }
}