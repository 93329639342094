package main

import interviews.interviewUI
import interviews.myTransition
import utils.alert
import utils.switchTemplate
import dev.fritz2.core.RenderContext
import dev.fritz2.core.storeOf
import dev.fritz2.headless.components.modal

fun RenderContext.appSettingsPanel() {
    val tstate = storeOf(true)

    modal {
        openState(store = tstate)
        modalPanel(interviewUI.modalPanel) {
            modalOverlay(interviewUI.modalOverlay) {
                myTransition()
                modalTitle(interviewUI.modalTitle) { +"Settings" }
                // modalDescription(interviewUI.modalDescription) { +"Have it your way" }

                // g/kg switch
                val gState = storeOf(false) // false = g, true = kg
                switchTemplate("Primary unit of measure", "g", "kg", gState, false)

                // dark mode switch
                val dState = storeOf(false) // false = light, true = dark
                switchTemplate("Dark mode", "Light", "Dark", dState, true)

                div(interviewUI.buttonBlock + " mt-6") {
                    button(interviewUI.nextButton) {
                        +"OK"
                        setInitialFocus
                        clicks handledBy {
                            alert("Sorry, settings are not yet implemented.")
                            tstate.update(false)
                        }
                    }
                    button(interviewUI.cancelButton) {
                        +"Cancel"
                        clicks handledBy {
                            tstate.update(false)
                        }
                    }
                }
            }
        }
    }
}
