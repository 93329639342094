package database

import utils.myLog
import utils.myLogError
import kotlin.math.roundToInt

/*
 * Calculates all the Qtys for all the stages of the formula. Set createFinal to true to have this function create
 * a final stage if there isn't one already.
 */

fun Formula.recalc(createFinal: Boolean): Formula {
    // Determine if there's a final stage
    val hasFinal = stages.last().stageType == StageType.final
    myLog("recalc: hasFinal = $hasFinal, createFinal = $createFinal")

    // Create a new list of stages that will replace the old one
    val newStageList = mutableListOf<Stage>()

    // Calculate the overall stage
    val overallStage = stages.first()
    val totalPercent = overallStage.ingredients.sumOf { it.percent }
    if (totalPercent == 0.0) {
        // Can't continue if we have no percents
        // myLog("calc2: totalPercent = 0.0")
        return this
    }
    val overallFlourWeight = totalSize / totalPercent
    val newOverallIngredients = overallStage.ingredients.map { it.copy(qty = it.percent * overallFlourWeight) }
    newStageList.add(overallStage.copy(ingredients = newOverallIngredients))

    // Create a list of overall stage ingredients, which we'll use to keep track of how much of each ingredient
    // is available to be allocated to a mid stage.
    val overallIngredients = newOverallIngredients.toMutableList()

    // And a local list of rollups
    val rollups = mutableListOf<Ingredient>()

    // ========================
    // Calculate the mid stages
    // ========================
    val midStages = stages.subList(1, stages.size - if (hasFinal) 1 else 0)
    val db = getIngredientDatabase()
    midStages.forEach { midStage ->

        // myLog("calc2: midStage = ${midStage.name}")
        val prefermentFlourWeight = overallFlourWeight * midStage.prefermentedFlour
        // myLog("calc2: prefermentFlourWeight = $prefermentFlourWeight")
        // myLog("calc2: overallFlourWeight = $overallFlourWeight, midStage.prefermentedFlour = ${midStage.prefermentedFlour}")

        // Create a new list of ingredients, which we'll build as we go along
        val newIngredients = mutableListOf<Ingredient>()

        midStage.ingredients.forEach ingredientLoop@ { ing ->
            // Find out how much of this ingredient is available. Either it's a regular ingredient, in which
            // case we'll find it in the overallIngredients list, or it's a rollup, in which case we'll find it
            // in the list of rollups that have already been calculated. In both cases, subtract the amount requested
            // from the total available.

            // Where this ingredient came from
            val isOverall: Boolean

            // Find the ingredient that corresponds to ing, either in the overallIngredients or in the rollups.
            val idx = overallIngredients.indexOfFirst { it.key == ing.key }
            val rollupIdx = rollups.indexOfFirst { it.key == ing.key }
            val theIngredient = if (idx >= 0) {
                isOverall = true
                overallIngredients[idx]
            } else {
                isOverall = false
                if (rollupIdx == -1) {
                    myLogError("ERROR: calc2: rollup ${ing.name} not found")
                    return@ingredientLoop // can't continue processing this ingredient
                } else {
                    rollups[rollupIdx]
                }
            }

            // How much this Stage wants
            val requestedQty =
                if (ing.useAll || midStage.stageType == StageType.soaker) {
                    // Use all that is available
                    theIngredient.qty
                } else {
                    // Use the percent of prefermented flour
                    ing.percent * prefermentFlourWeight
                }

            // How much it gets
            val newQty : Double

            // Now determine how much of this ingredient we can give it, and how much of the source ingredient
            // is left.
            var usedUp = ing.useOnly
            val theIngredientBalance =
                if (ing.useOnly) {
                    // Use the requested amount and don't carry the rest forward
                    // myLog("calc2: useOnly: using only $requestedQty of ${ing.name}")
                    newQty = requestedQty
                    0.0
                } else {
                    if (requestedQty <= theIngredient.qty) {
                        newQty = requestedQty
                        if (requestedQty == theIngredient.qty) {
                            usedUp = true
                            0.0
                        } else {
                            theIngredient.qty - requestedQty
                        }
                    } else {
                        // myLog("calc2: not enough ${ing.name} in overallIngredients")
                        // TODO: Alert the user that there's not enough of this ingredient
                        newQty = theIngredient.qty
                        usedUp = true
                        0.0
                    }
                }
            // myLog("calc2: theIngredientBalance = $theIngredientBalance")

            // Update the source ingredient
            val updatedIngredient = theIngredient.copy(qty = theIngredientBalance, isUsed = usedUp)
            if (isOverall) {
                overallIngredients[idx] = updatedIngredient
            } else {
                rollups[rollupIdx] = updatedIngredient
            }
            // myLog("calc2: updated ${theIngredient.name} to ${updatedIngredient.qty}")

            // Determine the % of this ingredient in the preferment
            val newPercent = if (midStage.stageType == StageType.soaker) 1.0 else (newQty / prefermentFlourWeight)

            // Add the ingredient, with its new qty and %, to our list
            newIngredients.add(ing.copy(qty = newQty, percent = newPercent))
        }
        // myLog("calc2: stage ${midStage.name} complete")
        val newStage = midStage.copy(ingredients = newIngredients.toList())
        newStageList.add(newStage)

        // Create a local rollup for this midStage.
        val stageQty = newStage.ingredients.sumOf { it.qty }
        myLog("calc2: updating rollup for ${newStage.name} with stageQty = $stageQty")

        // Copy the rollup ingredient from the IngredientDatabase. If it's not there, create it.
        myLog("Calc2: A")
        val rollupSrc = db.ingredients.firstOrNull{ it.isRollup && it.rollupStageKey == midStage.key }
        myLog("Calc2: B")
        myLog("calc2: found rollup ingredient ${if (rollupSrc != null) rollupSrc.name else "null"}")
        val localRollup = if (rollupSrc != null) {
            rollupSrc.copy(qty = stageQty, percent = 1.0, saveThis = true)
        } else {
            Ingredient(
                name            = midStage.name,
                qty             = stageQty,
                percent         = 1.0,
                isFlour         = false,
                units           = Units.G,
                isRollup        = true,
                rollupStageKey  = midStage.key,
                isUsed          = false,
                saveThis        = true,
            )
        }
        rollups.add(localRollup)
        myLog("Calc2: C")
    }

    // Copy the local rollups back to the IngredientDatabase.
    myLog("calc2: ROLLUPS: ${rollups.map { it.name }}")
    val dbTemp = db.updateAndAddIngredients(rollups.toList())
    val dbTempRollups = dbTemp.ingredients.filter { it.isRollup }
    myLog("calc2: updated ROLLUPS in IngredientDatabase = ${dbTempRollups.map { it.name }}")
    setIngredientDatabase( dbTemp )

    // ===================================================
    // Calculate the final stage. Create one if requested.
    // ===================================================
    if (hasFinal || createFinal) {
        myLog("calc2: calculating final stage, hasFinal = $hasFinal, createFinal = $createFinal")
        val lastStage = if (hasFinal) stages.last() else Stage(stageType = StageType.final, name = "Final Formula")
        // CalcFinalStage must use the Qty values we just calculated, not the Qtys in the existing formula. So we send
        // it a temporary formula with those new values.
        val tempFormula = copy(stages = newStageList.toList())
        val newFinalStage = calcFinalStage(tempFormula, lastStage)
        newStageList.add(newFinalStage)
    }
    myLog("calc2: returning this newStageList: ${newStageList.map { it.name }}")
    return copy(stages = newStageList.toList())
}

/*
 * Calculates the final stage. The Stage parameter is either a temporary stage into which the final stage gets
 * built, or it's the final stage itself, which will get replaced. Expects the caller has already called
 * calcGrams(), so there are grams from which the final stage can be calculated. Does not modify the formula --
 * that's up to the caller.
 */
private fun calcFinalStage(f: Formula, finalStage: Stage): Stage {
    // myLog("calcFinalStage: finalStage = ${finalStage.name}")
    val stageList = f.stages
    // Start with a copy of the overall stage ingredients. As we go through each stage, we'll subtract what
    // gets used, leaving us with the final ingredients. Each stage also becomes an ingredient, unless it gets
    // used in a subsequent stage.
    val finalIngredients = stageList[0].ingredients.toMutableList()
    // If there's a final stage, remove it from the list of stages we're considering
    val hasFinal = (stageList.last().stageType == StageType.final)
    val midStages = stageList.subList(1, stageList.size - if (hasFinal) 1 else 0)
    midStages.forEach { midStage ->
        midStage.ingredients.forEach { midIngredient ->
            // find each ingredient in this stage in the overall ingredients
            val idx = finalIngredients.indexOfFirst { it.key == midIngredient.key }
            if (idx >= 0) {
                val finalIngredient = finalIngredients[idx]
                val newQty = if (midIngredient.useOnly) 0.0 else finalIngredient.qty - midIngredient.qty

                // myLog("calcFinalStage: ${midIngredient.name} final qty = $newQty")
                val newQtyRounded = try {
                    if (newQty > 0.1) newQty else (newQty * 10.0).roundToInt() / 10.0
                } catch (e: Exception) {
                    myLogError("calcFinalStage: error rounding newQty = $newQty")
                    0.0
                }
                // myLog("calcFinalStage: ${midIngredient.name} newQtyrounded = $newQtyRounded")
                if (newQtyRounded > 0.0) {
                    finalIngredients[idx] = finalIngredient.copy(qty = newQty)
                } else {
                    finalIngredients.removeAt(idx)
                }
            } else {
                // Rollups that have been used in a stage end up here. They don't appear on the overall stage.
                // myLog("calcFinalStage: ingredient ${midIngredient.name} not found in finalIngredients")
            }
        }
    }
    // Now look through the ingredientDatabase for any RollUps that haven't been used
    val db = getIngredientDatabase()
    val rollups = db.ingredients.filter { it.isRollup } //  && !it.isUsed && it.qty > 0.0
    rollups.forEach{ r -> myLog("calcFinalStage: rollup ${r.name} isUsed = ${r.isUsed}, qty = ${r.qty}") }
    finalIngredients += rollups.filter { !it.isUsed && it.qty > 0.0 }

    // Finalize the final stage
    return finalStage.copy(ingredients = finalIngredients.toList())
}
