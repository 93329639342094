// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package database

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.Boolean
import kotlin.Double
import kotlin.String
import kotlin.collections.List

public fun Stage.Companion.key(): Lens<Stage, String> = lensOf(
    "key",
    { it.key },
    { p, v -> p.copy(key = v)}
  )

public fun <PARENT> Lens<PARENT, Stage>.key(): Lens<PARENT, String> = this + Stage.key()

public fun Stage.Companion.name(): Lens<Stage, String> = lensOf(
    "name",
    { it.name },
    { p, v -> p.copy(name = v)}
  )

public fun <PARENT> Lens<PARENT, Stage>.name(): Lens<PARENT, String> = this + Stage.name()

public fun Stage.Companion.stageType(): Lens<Stage, StageType> = lensOf(
    "stageType",
    { it.stageType },
    { p, v -> p.copy(stageType = v)}
  )

public fun <PARENT> Lens<PARENT, Stage>.stageType(): Lens<PARENT, StageType> = this +
    Stage.stageType()

public fun Stage.Companion.prefermentedFlour(): Lens<Stage, Double> = lensOf(
    "prefermentedFlour",
    { it.prefermentedFlour },
    { p, v -> p.copy(prefermentedFlour = v)}
  )

public fun <PARENT> Lens<PARENT, Stage>.prefermentedFlour(): Lens<PARENT, Double> = this +
    Stage.prefermentedFlour()

public fun Stage.Companion.prefermentUnitType(): Lens<Stage, Boolean> = lensOf(
    "prefermentUnitType",
    { it.prefermentUnitType },
    { p, v -> p.copy(prefermentUnitType = v)}
  )

public fun <PARENT> Lens<PARENT, Stage>.prefermentUnitType(): Lens<PARENT, Boolean> = this +
    Stage.prefermentUnitType()

public fun Stage.Companion.ingredients(): Lens<Stage, List<Ingredient>> = lensOf(
    "ingredients",
    { it.ingredients },
    { p, v -> p.copy(ingredients = v)}
  )

public fun <PARENT> Lens<PARENT, Stage>.ingredients(): Lens<PARENT, List<Ingredient>> = this +
    Stage.ingredients()

public fun Stage.Companion.process(): Lens<Stage, String> = lensOf(
    "process",
    { it.process },
    { p, v -> p.copy(process = v)}
  )

public fun <PARENT> Lens<PARENT, Stage>.process(): Lens<PARENT, String> = this + Stage.process()
