package interviews

import dev.fritz2.core.RenderContext

fun RenderContext.specialIngredientInterview(itype: Char) {
    when (itype) {
        'e' -> specialIngredientInterviewExtend()
        'u' -> specialIngredientInterviewUnique()
        else -> {}
    }
}
