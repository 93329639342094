package database

import io.github.jan.supabase.auth.auth
import io.github.jan.supabase.postgrest.from
import kotlinx.serialization.encodeToString
import model.FormulaParams
import model.JSON_FORMAT
import session.HttpSession.fetchClient
import utils.myLog
import utils.myLogError

private fun makeTitleKey(f: Formula): String {
    return f.title +
            (if (f.author.isNotBlank()) " by " + f.author else "") +
            if (f.basedOn.isNotBlank()) " via " + f.basedOn else ""
}

/*
 * Saves the current formula to the database, along with its rollups.
 */
suspend fun saveFormula(f: Formula, db: IngredientDatabase): Boolean {
    val customer = CustomerSession.get()
    val client = fetchClient()
    val user = client.auth.currentUserOrNull() ?: throw IllegalStateException("User is not logged in")
    myLog("saveFormula: user logged in = ${customer.first_name}")
    val titleKey = makeTitleKey(f)
    val formulaEncoding = JSON_FORMAT.encodeToString(f)
    val rollupEncoding = JSON_FORMAT.encodeToString(db.ingredients.filter{ it.isRollup })
    // db.dump()
    myLog("saveFormula: owner_id = ${customer.id}")
    myLog("saveFormula: formulaEncoding = ${formulaEncoding.take(50)}")
    myLog("saveFormula: rollupEncoding = ${rollupEncoding.take(50)}")
    val formulaParams = FormulaParams(
        title_key = titleKey,
        owner_id = customer.id,
        formula = formulaEncoding,
        rollups = rollupEncoding
    )
    myLog("title_key = ${formulaParams.title_key}")
    myLog("owner_id = ${formulaParams.owner_id}")

    try {
        client.from("formulas").upsert(value = formulaParams) {
            onConflict = "title_key, owner_id"  // If these fields match, update the record
            ignoreDuplicates = false            // If a duplicate is found, merge the data
        }
        // Tell the IngredientDatabase to save its special ingredients
        db.save()
    } catch (e: Exception) {
        myLogError("Save formula failed with exception: ${e.message}")
        return false
    }
    return true
}