package auth

import dev.fritz2.core.render
import dev.fritz2.core.src
import model.UI
import routing.Router

fun welcomeNewUser() {
    render {
        div("max-w-xl mx-auto mt-12") {
            div("max-w px-8 py-1 bg-white shadow-lg rounded-lg") {
                // Version
                div("flex justify-end") {
                    p("text-right text-xs text-zinc-300") {
                        +UI.VERSION
                    }
                }
                // logo
                div("flex justify-center mt-4") {
                    img("w-[200px]") {
                        src("bf-logo-lg.png")
                    }
                }
                // Welcome message
                div("mt-2 w-full p-8 mt-10 flex flex-col items-center") {
                    h1("text-2xl font-bold text-emerald-800") {
                        +"You're in!"
                    }
                    p("mt-4 text-lg text-slate-800 font-semibold text-center") {
                        +"Welcome to Baker's Formulary. We're excited to have you on board. Let's get started!"
                    }
                    div("mt-4 w-full flex flex-row justify-center") {
                        button("bg-lime-300 text-black font-bold p-4 rounded-lg px-8 justify-self-center mt-4 ring-1 ring-zinc-800 hover:bg-lime-100") {
                            +"Take me to the app!"
                            clicks handledBy Router.UserParamsStore.processNewUserReturn
                        }
                    }
                }
            }
        }
    }
}