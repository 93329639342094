package money

import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.render
import dev.fritz2.core.storeOf
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.portalRoot
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import model.UI
import routing.Router


fun RenderContext.priceCard(monthly: Store<Boolean>, price: Double, title: String, subtitle: String, features: List<String>, tips: List<String>) {
    // Add a link-previous icon in top left corner to return to app
    div("fixed top-0 left-[10px] top-[10px] h-[24px] w-[24px] flex items-center justify-center border border-zinc-800 hover:bg-zinc-100 cursor-pointer") {
        span("icon-[grommet-icons--link-previous]") {}
    }.clicks handledBy { Router.routeTo(Router.Page.APP) }

    div("border border-slate-200 rounded-lg shadow-sm divide-y divide-slate-200") {
        div("p-6") {
            h2("text-2xl font-semibold text-primary") { +title }
            p("mt-4 text-base text-primaryLight") { +subtitle }
        }
        div("p-6") {
            monthly.data.render { monthlyPrice ->
                val cost = if (monthlyPrice) price else price * 10.0
                val formattedCost = if (cost == 0.0) "0" else cost.asDynamic().toFixed(2) as String
                p("mt-4") {
                    span("text-4xl font-bold text-primary tracking-tighter") { +"$$formattedCost" }
                    span("text-base font-medium text-primaryLight") { +if (monthlyPrice) "/mo" else "/yr" }
                }
            }
        }
        div("p-6") {
            button("w-full bg-accent text-primary rounded-lg px-6 py-3 text-lg font-semibold hover:bg-accentHover") {
                +"Join with $title"
            }
        }
        div("pt-6 pb-8 px-6") {
            h3("text-sm font-bold text-primary tracking-wide uppercase") { +"What's Included" }
            ul("mt-4 space-y-3") {
                attr("role", "list")
                features.forEachIndexed { i, f ->
                    li("flex items-center space-x-3") {
                        span("icon-[grommet-icons--checkbox-selected] text-accent") {}
                        p { +f }.tooltip(UI.tooltipStyle) {
                            +tips[i]
                            arrow()
                            placement = PlacementValues.right
                        }
                    }
                }
            }
        }
    }
}

fun pricingPanel(lowPrice: Double, midPrice: Double, highPrice: Double) {
    render {
        val monthlyBilling = storeOf(true)
        div("sm:flex sm:flex-col sm:align-center p-10") {
            div("relative self-center bg-slate-200 rounded-lg p-0.5 flex") {
                val buttonFormat =
                    "relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none " +
                            "sm:w-auto sm:px-8 text-slate-900"
                monthlyBilling.data.render { monthly ->
                    button(buttonFormat + if (!monthly) " bg-slate-100 border-slate-50 shadow-sm" else " bg-white border-transparent") {
                        +"Monthy billing"
                        clicks handledBy { monthlyBilling.update(true) }
                    }
                    button(buttonFormat + " ml-0.5" + if (monthly) " bg-slate-100 border-slate-50 shadow-sm" else " bg-white border-transparent") {
                        +"Yearly billing"
                        span("text-accent") { +"*" }
                        clicks handledBy { monthlyBilling.update(false) }
                    }
                }

            }
            p("mt-2 text-primaryLight text-xs self-center") {
                span("text-accent") {+"*"}
                +" get 2 months free with yearly billing"
            }
        }
        div("mt-12 space-y-3 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 md:max-w-5xl md:mx-auto xl:grid-cols-3") {
            val lowList = listOf(
                "Up to 5 saved formulas",
                "Unlimited sharing",
                "Ingredient database",
                "Add new ingredients",
                "Unlimited recipe stages",
                "Unlimited ingredients per stage",
                "Recipe attribution",
            )
            val lowTips = listOf(
                "Save your formulas in our online database",
                "Share your saved formulas with friends on social media or by email",
                "Access to a database of common ingredients you can use in your formulas",
                "Add new ingredients to the database",
                "Add as many formula steps as you need (like soakers, sponges, levains, etc.)",
                "Add as many ingredients as you need to each stage",
                "You can indicate who created the formula and what it's based on",
            )

            val midList = listOf(
                "Everything in the Starter plan",
                "Up to 50 saved formulas",
                "Ingredient groups",
            )
            val midTips = listOf(
                "",
                "Save up to 50 formulas in your account",
                "Import groups of related ingredients into the ingredient database. For example, you can import common ingredients for sweet baking, or rye baking.",
            )

            val highList = listOf(
                "Everything in the Home plan",
                "Unlimited saved formulas",
                "Hydration calculator",
                "Formula versioning",
                "Formula ratings",
                "Formula import",
            )
            val highTips = listOf(
                "",
                "Save as many formulas as you like",
                "Calculate the hydration of your formula",
                "Save different versions of your formula",
                "Allow others to rate your formulas",
                "Import formulas from spreadsheets and PDFs",
            )

            priceCard(monthlyBilling, lowPrice, "Starter", "Try it out, see what bubbles up", lowList, lowTips)
            priceCard(monthlyBilling, midPrice, "Home", "For home bakers", midList, midTips)
            priceCard(monthlyBilling, highPrice, "Pro", "For pro bakers and teachers", highList, highTips)

        }
        if (lowPrice != 0.0) {
            div("w-full mt-4 flex justify-center") {
                p("mt-4 w-full text-xl italic text-primary text-center") {
                    +"All plans come with a 30-day free trial"
                }
            }
        }
        portalRoot()
    }
}