// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package database

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.Boolean
import kotlin.Double
import kotlin.Long
import kotlin.String

public fun Ingredient.Companion.id(): Lens<Ingredient, Long> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.id(): Lens<PARENT, Long> = this + Ingredient.id()

public fun Ingredient.Companion.name(): Lens<Ingredient, String> = lensOf(
    "name",
    { it.name },
    { p, v -> p.copy(name = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.name(): Lens<PARENT, String> = this + Ingredient.name()

public fun Ingredient.Companion.isFlour(): Lens<Ingredient, Boolean> = lensOf(
    "isFlour",
    { it.isFlour },
    { p, v -> p.copy(isFlour = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.isFlour(): Lens<PARENT, Boolean> = this +
    Ingredient.isFlour()

public fun Ingredient.Companion.units(): Lens<Ingredient, Units> = lensOf(
    "units",
    { it.units },
    { p, v -> p.copy(units = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.units(): Lens<PARENT, Units> = this +
    Ingredient.units()

public fun Ingredient.Companion.conversion(): Lens<Ingredient, Double> = lensOf(
    "conversion",
    { it.conversion },
    { p, v -> p.copy(conversion = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.conversion(): Lens<PARENT, Double> = this +
    Ingredient.conversion()

public fun Ingredient.Companion.convUnit(): Lens<Ingredient, Units> = lensOf(
    "convUnit",
    { it.convUnit },
    { p, v -> p.copy(convUnit = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.convUnit(): Lens<PARENT, Units> = this +
    Ingredient.convUnit()

public fun Ingredient.Companion.group(): Lens<Ingredient, IngredientGroup> = lensOf(
    "group",
    { it.group },
    { p, v -> p.copy(group = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.group(): Lens<PARENT, IngredientGroup> = this +
    Ingredient.group()

public fun Ingredient.Companion.ownerId(): Lens<Ingredient, Long?> = lensOf(
    "ownerId",
    { it.ownerId },
    { p, v -> p.copy(ownerId = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.ownerId(): Lens<PARENT, Long?> = this +
    Ingredient.ownerId()

public fun Ingredient.Companion.key(): Lens<Ingredient, String> = lensOf(
    "key",
    { it.key },
    { p, v -> p.copy(key = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.key(): Lens<PARENT, String> = this + Ingredient.key()

public fun Ingredient.Companion.qty(): Lens<Ingredient, Double> = lensOf(
    "qty",
    { it.qty },
    { p, v -> p.copy(qty = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.qty(): Lens<PARENT, Double> = this + Ingredient.qty()

public fun Ingredient.Companion.percent(): Lens<Ingredient, Double> = lensOf(
    "percent",
    { it.percent },
    { p, v -> p.copy(percent = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.percent(): Lens<PARENT, Double> = this +
    Ingredient.percent()

public fun Ingredient.Companion.isRollup(): Lens<Ingredient, Boolean> = lensOf(
    "isRollup",
    { it.isRollup },
    { p, v -> p.copy(isRollup = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.isRollup(): Lens<PARENT, Boolean> = this +
    Ingredient.isRollup()

public fun Ingredient.Companion.rollupStageKey(): Lens<Ingredient, String> = lensOf(
    "rollupStageKey",
    { it.rollupStageKey },
    { p, v -> p.copy(rollupStageKey = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.rollupStageKey(): Lens<PARENT, String> = this +
    Ingredient.rollupStageKey()

public fun Ingredient.Companion.isUsed(): Lens<Ingredient, Boolean> = lensOf(
    "isUsed",
    { it.isUsed },
    { p, v -> p.copy(isUsed = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.isUsed(): Lens<PARENT, Boolean> = this +
    Ingredient.isUsed()

public fun Ingredient.Companion.saveThis(): Lens<Ingredient, Boolean> = lensOf(
    "saveThis",
    { it.saveThis },
    { p, v -> p.copy(saveThis = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.saveThis(): Lens<PARENT, Boolean> = this +
    Ingredient.saveThis()

public fun Ingredient.Companion.usePercent(): Lens<Ingredient, Boolean> = lensOf(
    "usePercent",
    { it.usePercent },
    { p, v -> p.copy(usePercent = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.usePercent(): Lens<PARENT, Boolean> = this +
    Ingredient.usePercent()

public fun Ingredient.Companion.useAll(): Lens<Ingredient, Boolean> = lensOf(
    "useAll",
    { it.useAll },
    { p, v -> p.copy(useAll = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.useAll(): Lens<PARENT, Boolean> = this +
    Ingredient.useAll()

public fun Ingredient.Companion.useOnly(): Lens<Ingredient, Boolean> = lensOf(
    "useOnly",
    { it.useOnly },
    { p, v -> p.copy(useOnly = v)}
  )

public fun <PARENT> Lens<PARENT, Ingredient>.useOnly(): Lens<PARENT, Boolean> = this +
    Ingredient.useOnly()
