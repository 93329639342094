// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.String

public fun UserLoginParams.Companion.email(): Lens<UserLoginParams, String> = lensOf(
    "email",
    { it.email },
    { p, v -> p.copy(email = v)}
  )

public fun <PARENT> Lens<PARENT, UserLoginParams>.email(): Lens<PARENT, String> = this +
    UserLoginParams.email()

public fun UserLoginParams.Companion.password(): Lens<UserLoginParams, String> = lensOf(
    "password",
    { it.password },
    { p, v -> p.copy(password = v)}
  )

public fun <PARENT> Lens<PARENT, UserLoginParams>.password(): Lens<PARENT, String> = this +
    UserLoginParams.password()

public fun UserLoginParams.Companion.captchaToken(): Lens<UserLoginParams, String> = lensOf(
    "captchaToken",
    { it.captchaToken },
    { p, v -> p.copy(captchaToken = v)}
  )

public fun <PARENT> Lens<PARENT, UserLoginParams>.captchaToken(): Lens<PARENT, String> = this +
    UserLoginParams.captchaToken()
