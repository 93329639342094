package interviews

import database.CustomerSession
import database.Ingredient
import database.IngredientDatabaseStore
import dev.fritz2.core.*
import dev.fritz2.headless.components.radioGroup
import kotlinx.coroutines.flow.map
import model.UI
import org.w3c.dom.HTMLFieldSetElement

fun RenderContext.specialIngredientInterviewExtend() {
    val newName = storeOf("")
    val choice = storeOf(Ingredient())

    fun RenderContext.extendPanelContent() {
        val db = IngredientDatabaseStore.current.ingredients

        div("w-full max-h-80 overflow-y-scroll mt-4") {
            radioGroup<HTMLFieldSetElement, Ingredient>(tag = RenderContext::fieldset) {
                value(choice)
                db.forEach { iFromDB ->
                    radioGroupOption(iFromDB) {
                        className(selected.map {
                            if (it) "bg-lime-700 hover:none text-white"
                            else "bg-slate-100 hover:bg-lime-200 text-slate-800"
                        })

                        radioGroupOptionToggle("grid grid-cols-8 grid-rows-1 gap-1 p-2 cursor-pointer focus:outline-none focus-visible:ring-4 focus-visible:ring-slate-600") {
                            div("col-start-1 pr-2") {
                                div("flex flex-row items-center bg-white w-6 h-6 rounded-full justify-center") {
                                    span("h-3 w-3 rounded-full") {
                                        className(selected.map {
                                            if (it) "bg-lime-300" else "bg-white"
                                        })
                                    }
                                }
                            }
                            div("col-start-2 col-span-7") {
                                radioGroupOptionLabel {
                                    +iFromDB.name
                                }
                            }
                        }
                    }
                }
            }
        }

        div("w-full text-center mt-4") {
            p("font-semibold") { +"New ingredient name:"}
            choice.data.render { c ->
                val cName = c.name + " 2"
                newName.update(cName)
                input("w-48 bg-white text-gray-700 px-4 pt-1 pb-2 mt-4 leading-tight ${UI.hoverColor}") {
                    type("text")
                    value(newName.data)
                    changes.values() handledBy newName.update
                }
            }
        }
    }

    fun buttonAction() {
        // myLog("specialIngredientInterviewExtend: choice.current = ${choice.current}")
        val specialIngredient = choice.current.copy(
            name        = newName.current,
            saveThis    = true,
            ownerId     = CustomerSession.get().id,
            id          = 0, // this is a new ingredient, so it doesn't get the ID of the one it's copied from
        )
        // myLog("specialIngredientInterviewExtend: specialIngredient = $specialIngredient")
        IngredientDatabaseStore.addIngredient(specialIngredient)
    }

    val parms = storeOf(
        ModalFrameworkParameterPak(
            title           = "Select Ingredient",
            description     = "Select the ingredient on which your new ingredient will be based.",
            panelContent    = RenderContext::extendPanelContent,
            buttonContent   = ::buttonAction,
            buttonLabel     = "Save",
        )
    )
    modalFramework(parms)
}
