package main_panel

import database.*
import dev.fritz2.core.*
import dev.fritz2.headless.components.textArea
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import interviews.PreFinalStageStore
import interviews.prefermentInterview
import interviews.soakerInterview
import kotlinx.coroutines.flow.map
import model.Formats
import model.UI
import org.w3c.dom.HTMLTextAreaElement
import utils.alert
import utils.myLog

fun RenderContext.formulaTitle() {
    val formulaTitleStore = FormulaStore.map(Formula.title())

    div("flex flex-row justify-center w-full mt-3") {
        textarea("w-3/4 font-bold px-4 py-2 bg-background border-2 border-spacing-2 border-lime-500 text-center resize-none overflow-none ${UI.hoverColor}") {
            rows(1) // Set the initial number of rows
            placeholder("Formula title")
            value(formulaTitleStore.data)
            changes.values() handledBy formulaTitleStore.update
            // This code adjusts the size of the textarea to fit the number of lines of wrapped text.
            // It grows and shrinks as the number of lines changes. Note that this depends on the current
            // font size and line height, and will fail if those change.
            domNode.apply {
                onkeyup = {
                    rows(1)
                    val lineHeight = 24 // pixels from baseline to baseline
                    val padding = 16    // total pixels of vertical padding
                    val myHeight = this.scrollHeight    // total pixels of entire text area
                    val lines = (myHeight - padding) / lineHeight
                    // myLog("myHeight: $myHeight, lines: $lines")
                    rows(lines)
                }
            }
        }
    }
}

fun RenderContext.authorBlock() {
    val authorStore = FormulaStore.map(Formula.author())
    val basedOnStore = FormulaStore.map(Formula.basedOn())
    val inputStyle = "px-3 py-2 text-xs bg-gridBackground text-left ${UI.hoverColor}"
    val labelStyle = "w-1/5 italic text-xs text-gray-700 text-right mr-2"

    div("flex flex-row justify-center w-3/4 mt-2") {
        div("flex flex-row w-5/12 justify-start items-center mr-2") {
            label(labelStyle) {
                +"By"
            }
            input(inputStyle) {
                type("text")
                placeholder("Author")
                value(authorStore.data)
                changes.values() handledBy authorStore.update
            }
        }
        div("ml-2 flex flex-row w-5/12 justify-start items-center") {
            label(labelStyle) {
                +"Via"
            }
            input(inputStyle) {
                type("text")
                placeholder("Based on")
                value(basedOnStore.data)
                changes.values() handledBy basedOnStore.update
            }
        }
    }
}

fun RenderContext.formulaSizer() {
    val tdwStore        = FormulaStore.map(Formula.totalSize() + Formats.quantity + Formats.addUnitG)
    val nPiecesStore    = FormulaStore.map(Formula.nPieces() + Formats.quantity)
    val pieceSizeStore  = FormulaStore.map(Formula.pieceSize() + Formats.quantity)

    div("flex justify-between w-full items-end mt-4") {
        div("flex justify-start gap-2") {
            div("relative") {
                label("block relative w-24 uppercase tracking-wide text-gray-700 text-xs font-bold mb-1") {
                    `for`("n-units")
                    +"# pieces"
                }

                input("block relative w-24 text-gray-700 py-1 px-2 leading-tight bg-lime-400 ${UI.hoverColor}") {
                    type("text")
                    id("n-units")
                    value(nPiecesStore.data)
                    changes.values() handledBy FormulaStore.updateNPieces
                }
            }
            div("relative") {
                label("block relative w-24 uppercase tracking-wide text-gray-700 text-xs font-bold mb-1") {
                    `for`("unit-size")
                    +"piece size (g)"
                }
                input("block relative w-24 text-gray-700 py-1 px-2 leading-tight ${UI.hoverColor} bg-lime-400") {
                    type("text")
                    id("unit-size")
                    value(pieceSizeStore.data)
                    changes.values() handledBy FormulaStore.updatePieceSize
                }
            }
        }
        div("flex flex-row justify-end") {
            div("relative") {
                label("block relative w-20 uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 text-right") {
                    `for`("total-size")
                    +"TDW"
                }.tooltip(UI.tooltipStyle) {
                    +"Total Dough Weight"
                    arrow()
                    placement = PlacementValues.right
                }
                input("block relative border-2 border-slate-400 w-20 bg-white text-lime-500 py-1 px-2 leading-tight text-right") {
                    disabled(true)
                    type("text")
                    id("total-size")
                    value(tdwStore.data)
                    changes.values() handledBy tdwStore.update
                }
            }
        }
    }
}

fun RenderContext.stageHeader(stageStore: Store<Stage>) {
    val stageNameStore = stageStore.map(Stage.name())
    val stageTypeStore = stageStore.map(Stage.stageType())
    val ingredientsStore = stageStore.map(Stage.ingredients())
    val inputFormat = "block relative text-center font-medium text-sm bg-white py-1 px-1 leading-tight ${UI.hoverColor} border border-gray-700 rounded -mt-8"
    div("flex flex-row justify-center gap-[2rem]") {

        // Stage name
        input("$inputFormat w-48") {
            type("text")
            placeholder("Stage name")
            value(stageNameStore.data)
            changes.values().map { newName -> Pair(stageStore.current.key, newName) } handledBy FormulaStore.renameStage
        }

        // Preferment %
        if (stageTypeStore.current == StageType.preferment) {
            val signLens = Formats.percent + Formats.addSign
            val prefermentStore = stageStore.map(Stage.prefermentedFlour() + signLens)
            input("$inputFormat w-20") {
                type("text")
                placeholder("Prefermented flour")
                value(prefermentStore.data)
                changes.values() handledBy prefermentStore.update
            }
        }

        // TDW of this stage
        ingredientsStore.data.render { ingredients ->
            val ingredientsSum = ingredients.sumOf { it.qty }
            val tdwStore = storeOf(ingredientsSum).map(Formats.quantity + Formats.addUnitG) // TODO: Format with current default units
            input("$inputFormat w-20") {
                type("text")
                disabled(true)
                value(tdwStore.data)
            }
        }
    }
}

fun RenderContext.processBlock(stageStore: Store<Stage>) {
    val processStore = stageStore.map(Stage.process())
    div("flex flex-row justify-start w-full mt-4") {
        textArea("w-full") {
            value(processStore)
            textareaTextfield("w-full bg-gridBackground text-sm ${UI.hoverColor} px-2 py-1") {
                placeholder("Process")
                +processStore.current
                changes.values() handledBy processStore.update
            }
        }

        // Resize on input
        domNode.addEventListener("input", {
            (it.target as? HTMLTextAreaElement)?.let(::resizeTextarea)
        })

    }
}

// Helper function to resize textarea
fun resizeTextarea(textarea: HTMLTextAreaElement) {
    textarea.style.height = "auto"
    textarea.style.height = "${textarea.scrollHeight}px"
}

fun RenderContext.stageDeleteButton(stageStore: Store<Stage>) {
    val stageTypeStore = stageStore.map(Stage.stageType())
    val stageKey = stageStore.current.key
    if (stageTypeStore.current != StageType.overall) {
        div("w-full relative") {
            button("absolute top-3 -right-14 text-slate-500 hover:text-red-500 icon-[grommet-icons--trash] text-xl") {
                clicks.map { stageKey } handledBy FormulaStore.deleteStage
            }
        }
    }
}



/*
 * Manages the process of creating a stage. If a stage requires collecting further information from the user, it
 * initiates an interview. If the stage is calculated, it initiates the calculations necessary to create the stage.
 */
fun RenderContext.stageCreatorBody() {
    myLog("stageCreator()")
    FormulaStore.data.render { f ->

        if (f.newStageRequest) {
            // To present the available ingredients to the user in preferment and soaker interviews, and to create a Final
            // stage, we need a final stage, even if there isn't one that's part of the formula. So we create one here, and
            // only add it to the formula if a final stage was requested. The interviews and createFinalStage() access this
            // through preFinalStageStore.
            //
            // But if there is only one stage (the Overall stage), we fake it by sending the overall ingredients to
            // preFinalStageStore, since newCalc() doesn't create a final with only one stage.
            myLog("stageCreator A: creating tempFormula")
            val tempFormula = f.recalc(true,) // Creates a formula with a final stage
            val tempFinalStage = tempFormula.stages.last()  // If there's only one stage, this will be the overall stage
            myLog("stageCreator B: tempFinalStage = ${tempFinalStage.name}")
            PreFinalStageStore.update(tempFinalStage)

            myLog("stageCreator C: newStageRequest = ${f.newStageRequest}")
            val st = f.newStageType
            myLog("stageCreator D: stageType = $st")
            when (st) {
                StageType.preferment -> prefermentInterview()
                StageType.soaker -> soakerInterview()
                StageType.final -> {
                    myLog("stageCreator E: createFinalStage()")
                    PreFinalStageStore.finalize()
                    FormulaStore.clearStageRequest()
                }
                else -> alert("Sorry, this feature is not yet implemented.")
            }
        } else {
            myLog("stageCreator: newStageRequest is false")
        }
    }
}

fun RenderContext.stageGroup() {
    myLog("stageGroup 1: stageCreator()")

    stageCreatorBody()
    var tabOffset = 0

    val stageList = FormulaStore.map(Formula.stages())
    stageList.renderEach(idProvider = Stage::key) { stageStore ->
        val stageType = stageStore.map(Stage.stageType())
        val stageFormat: IngredientGridFactoryParameterPack = when (stageType.current) {
            StageType.overall -> OverallGridFormat
            StageType.preferment -> MidStageGridFormat
            StageType.soaker -> MidStageGridFormat
            StageType.final -> FinalGridFormat
        }
        val ingredientCount = stageStore.current.ingredients.count()
        val columnCount = stageFormat.columnHeadings.labels.count()
        val nextCellCount = tabOffset + ingredientCount * columnCount
        // myLog("stageGroup A: ingredientCount = $ingredientCount, columnCount = $columnCount, nextCellCount = $nextCellCount")

        div("w-full border-t-[2em] border-indigo-800 p-0 mt-8") {
            stageDeleteButton(stageStore)
            stageHeader(stageStore)
            ingredientTable(stageStore, stageFormat, tabOffset)
            processBlock(stageStore)
            tabOffset = nextCellCount
            // myLog("stageGroup B: nextCellCount = $nextCellCount tabOffset = $tabOffset")
        }
    }

    // Add empty space at the bottom, to allow room to expand the process block of the last stage
    div("h-32") {}
}

fun RenderContext.formulaBlock() {
    formulaTitle()
    authorBlock()
    formulaSizer()
    stageGroup()
}
