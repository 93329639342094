package auth

import dev.fritz2.core.*
import dev.fritz2.headless.foundation.portalRoot
import kotlinx.browser.window
import kotlinx.coroutines.flow.map
import model.*
import model.UI.ALLOW_SIGNUP
import routing.Router


// Callback for the captcha, updates the token in the UserParamsStore
fun setupCallback() {
    window.asDynamic().captchaDataCallback = { token: String ->
        // println("Captcha token: ${token.take(15)}")
        val cToken = Router.UserParamsStore.map(UserLoginParams.captchaToken())
        cToken.update(token)
    }
}

fun RenderContext.createCaptchaElement() {
    div("cf-turnstile w-full flex flex-row justify-center mt-4") {
        id("captcha")
        attr("data-sitekey", "0x4AAAAAAAUh7bCokqfbMZnK")
        attr("data-callback", "captchaDataCallback")
        attr("data-theme", "light")
    }
}

fun loginPage() {

    val userParms = Router.UserParamsStore
    val username = userParms.map(UserLoginParams.email())
    val password = userParms.map(UserLoginParams.password())

    setupCallback()

    render {
        div("max-w-xl mx-auto mt-12") {
            div("max-w px-8 py-1 bg-white shadow-lg rounded-lg") {
                // Version
                div("flex justify-end") {
                    p("text-right text-xs text-zinc-300") {
                        +UI.VERSION
                    }
                }
                // logo
                div("flex justify-center mt-4") {
                    img("w-[200px]") {
                        src("bf-logo-lg.png")
                    }
                }
                div("mt-2 w-full px-8 py-1 mt-10") {
                    input("text-xl bg-gray-100 w-full p-2 rounded-lg border border-gray-200") {
                        type("text")
                        placeholder("Username")
                        value(username.data)
                        changes.values() handledBy username.update
                    }
                }
                div("w-full px-8 py-1 mt-4") {
                    input("text-xl bg-gray-100 w-full p-2 rounded-lg border border-gray-200") {
                        type("password")
                        placeholder("Password")
                        value(password.data)
                        changes.values() handledBy password.update
                    }
                }

                createCaptchaElement()

                div("flex-row justify-center mt-6 mb-4 w-full text-center") {
                    button("bg-lime-300 font-bold p-4 rounded-lg px-8 justify-self-center cursor-pointer") {
                        +"Login"
                        clicks.map {
                            userParms.current
                        } handledBy Router.UserParamsStore.processLogin
                    }
                }
                div("flex-row justify-center mt-6 mb-4 w-full text-center") {
                    if (ALLOW_SIGNUP) {
                        a("cursor-pointer") {
                            +"No login? Sign up for a free account!"
                            // href("#SIGNUP")
                            clicks.handledBy { Router.routeTo(Router.Page.SIGNUP) }
                        }
                    } else {
                        a("cursor-pointer") {
                            href("https://ba5cd1a3.sibforms.com/serve/MUIFAD_OmTeZyjBegiRcr740LVBkvEWO3gMVEQYY-k6fnwT29732sWZuqLw9blEE18V55mjVhHQMdGJ8sUdWsuhC_ISRC3bXS0trgEXRfaVzjmMbKZ5GGNcWV2TXEQprL39yx2VGJmI8cyBp-mHjalQU4xROdwuPapB90WAqlySyw45Bc9KSYh5NV7BaQIaW247UiPkjdh2sRuXD")
                            +"No login? Join our waiting list!"
                        }
                    }
                }
            }
        }
        portalRoot()
    }

}

