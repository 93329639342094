package database

import dev.fritz2.core.Handler
import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job
import model.JsonStringPack
import utils.myLog

actual fun loadIngredientsFromDatabase(db: IngredientDatabase) {
    IngredientDatabaseStore.updateAndAddIngredients(db.ingredients)
}

actual fun loadIngredientsError(message: String) {
    myLog("Load ingredients failed with message: $message")
}

actual fun getIngredientDatabase(): IngredientDatabase {
    return IngredientDatabaseStore.current
}

actual fun setIngredientDatabase(db: IngredientDatabase) {
    IngredientDatabaseStore.update(db)
}

val idbJob = Job()

object IngredientDatabaseStore: RootStore<IngredientDatabase>(IngredientDatabase(), job = idbJob)
{
    val addIngredient: Handler<Ingredient> = handle { db, i ->
        val preSize = db.ingredients.size
        myLog("addIngredient: Adding ingredient ${i.name} with isRollup = ${i.isRollup}, size = $preSize")
        val updatedDb = db.addIngredient(i)
        myLog("addIngredient: Updated ingredient list size = ${updatedDb.ingredients.size}")
        updatedDb
    }

    val updateIngredient: Handler<Ingredient> = handle { db, i ->
        myLog("updateIngredient: Updating ingredient ${i.name}")
        db.updateIngredient(i)
    }

    val updateIngredients: Handler<IngredientDatabase> = handle { currDb, newDb ->
        myLog("updateIngredients: Updating ingredients")
        currDb.updateIngredients(newDb)
    }

    val updateAndAddIngredients: Handler<List<Ingredient>> = handle { currDb, newList ->
        myLog("updateAndAddIngredients: Updating and adding ingredients")
        currDb.updateAndAddIngredients(newList)
    }

    val renameRollup: Handler<Pair<String, String>> = handle { db, (stageKey, newName) ->
        myLog("renameRollup: Renaming rollup with stageKey = $stageKey to $newName")
        db.renameRollup(stageKey, newName)
    }

    val jsonLoad: Handler<JsonStringPack> = handle { db, jsp ->
        myLog("jsonLoad: Loading JSON")
        db.jsonLoad(jsp)
    }

    val clearRollups: Handler<Unit> = handle { db ->
        myLog("clearRollups: Clearing rollups")
        db.clearRollups()
    }

    val load: Handler<IngredientGroup> = handle { db, group ->
        myLog("load: Loading ingredient group")
        db.load(group)
        db
    }
}

