package main_panel

import database.*
import dev.fritz2.core.RenderContext
import dev.fritz2.core.placeholder
import dev.fritz2.core.value
import dev.fritz2.core.values
import dev.fritz2.headless.components.modal
import dev.fritz2.headless.components.toast
import interviews.interviewUI
import interviews.myTransition
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.launch
import routing.Router
import utils.myLog


fun RenderContext.savePanel(): Boolean {
    val tstate = dev.fritz2.core.storeOf(true, job = Job())
    val titleStore = FormulaStore.map(Formula.title())
    val authorStore = FormulaStore.map(Formula.author())
    val basedOnStore = FormulaStore.map(Formula.basedOn())
    var returnValue = false
    val inputStyle = "mt-1 block w-3/4 px-3 py-2 border border-gray-300 rounded-md shadow-sm " +
        "focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"

    modal {
        this.openState(store = tstate)
        modalPanel(interviewUI.modalPanel) {
            modalOverlay(" ${interviewUI.modalOverlay} max-w-2xl") {
                myTransition()
                modalTitle(interviewUI.modalTitle) { +"Save" }
                modalDescription(interviewUI.modalDescription) { +"Enter a formula name and attribution" }
                // Formula title
                div("mt-4 w-full flex flex-col justify-start") {
                    label(interviewUI.labelFormat + "w-3/4") {
                        +"Formula Title"
                    }
                    input(inputStyle)
                    {
                        placeholder("Enter a title")
                        value(titleStore.data)
                        changes.values() handledBy titleStore.update
                    }
                    label(interviewUI.labelFormat + "w-3/4 mt-2") {
                        +"Author"
                    }
                    input(inputStyle)
                    {
                        placeholder("Author")
                        value(authorStore.data)
                        changes.values() handledBy authorStore.update
                    }
                    label(interviewUI.labelFormat + "w-3/4 mt-2") {
                        +"Based On"
                    }
                    input(inputStyle)
                    {
                        placeholder("Based On")
                        value(basedOnStore.data)
                        changes.values() handledBy basedOnStore.update
                    }
                }

                // Buttons
                div("mt-4 w-full flex flex-row justify-center") {
                    button("mr-2 ${interviewUI.nextButton}") {
                        +"Save"
                        clicks handledBy {
                            tstate.update(false)
                            returnValue = true
                        }
                    }
                    button("ml-2 ${interviewUI.cancelButton}") {
                        +"Cancel"
                        clicks handledBy {
                            tstate.update(false)
                            returnValue = false
                        }
                    }
                }
            }
        }
    }
    return returnValue
}

/*
 * Checks that the formula has a title. If not, it asks the user to enter one. If yes, it saves
 * the formula and its ingredients to the server.
 */
fun RenderContext.confirmAndSave() {
    val title = FormulaStore.map(Formula.title())
    if (title.current.isNotBlank() || savePanel()) {
        Router.coroutineScope.launch {
            val f = FormulaStore.data.first()
            val result = saveFormula(f, IngredientDatabaseStore.current)
            myLog("confirmAndSave saveFormula result: $result")
            if (result) {
                toast(
                    containerName = "status",
                    duration = 3000,
                    classes = "w-40 h-10 flex flex-col items-center bg-lime-300 text-sm font-bold rounded-sm p-2",
                    id = "save-status-toast"
                )
                {
                    +"Formula saved"
                }
                myLog("Formula saved")
                return@launch
            } else {
                myLog("Save failed (result false)")
            }
        }
    }
}

