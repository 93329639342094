package interviews

import database.*
import database.Ingredient
import dev.fritz2.core.*
import main_panel.*
import model.ColumnHeadingFactoryParameterPack
import model.UI
import utils.myLog
import utils.switchTemplate

fun RenderContext.prefermentInterviewPart3() {

    fun RenderContext.interviewIngredientNameColumn(p: actionFnParameterPack) {
        val nameStore = p.ingredient.map(Ingredient.name())
        input("w-full h-full bg-gray-200 text-gray-700 px-4 py-2 leading-tight text-xs") {
            type("text")
            disabled(p.disable)
            id("grid-col-${p.gridCol}")
            value(nameStore.data)
            // not updatable -- display only
        }
    }

    fun RenderContext.switchColumn(p: actionFnParameterPack) {
        val switchStore = p.ingredient.map(Ingredient.usePercent())
        div("grid place-content-center w-full h-full bg-gray-200 text-gray-700" ) {
            div("flex justify-center items-center h-full w-full") {
                switchTemplate(
                    description = "",
                    leftLabel   = "",
                    rightLabel  = "",
                    tState      = switchStore, // true = %, false = g
                    onOff       = false
                )
            }
        }
    }

    fun RenderContext.useAllColumn(p: actionFnParameterPack) {
        val useAllStore = p.ingredient.map(Ingredient.useAll())
        val useOnlyStore = p.ingredient.map(Ingredient.useOnly())
        useOnlyStore.data.render { useOnly ->
            div("grid place-content-center w-full h-full bg-gray-200 text-gray-700" + if (!useOnly) " ${UI.hoverColor}" else "") {
                input("p-4 w-5 h-5") {
                    type("checkbox")
                    id("grid-col-${p.gridCol}")
                    disabled(useOnly)
                    checked(useAllStore.data)
                    changes.states() handledBy useAllStore.update
                }
            }
        }
    }

    fun RenderContext.useOnlyColumn(p: actionFnParameterPack) {
        val useOnlyStore = p.ingredient.map(Ingredient.useOnly())
        val useAllStore = p.ingredient.map(Ingredient.useAll())
        useAllStore.data.render { useAll ->
            div("grid place-content-center w-full h-full bg-gray-200 text-gray-700" + if (!useAll) " ${UI.hoverColor}" else "") {
                input("p-4 w-5 h-5") {
                    type("checkbox")
                    id("grid-col-${p.gridCol}")
                    disabled(useAll)
                    checked(useOnlyStore.data)
                    changes.states() handledBy useOnlyStore.update
                }
            }
        }
    }

    fun RenderContext.panelContentAction() {
        div {
            val labels = listOf("Ingredient", "Qty", "*% / g", "*Use all", "*Use only")
            val colSpans = listOf(2, 1, 1, 1, 1)
            val tips = listOf(
                "",
                "Amount of this ingredient in this preferment",
                "Whether the amount specified is percent or grams",
                "Use all of this ingredient; the percent will be calculated for you",
                "Use only the specified amount of this ingredient, and don't carry the rest forward. Primarily useful for starter."
            )
            columnHeadingFramework(storeOf(ColumnHeadingFactoryParameterPack(gridSpans = colSpans, labels = labels, tips = tips)))

            val actions = listOf(
                RenderContext::interviewIngredientNameColumn,
                RenderContext::qtyColumn,
                RenderContext::switchColumn,
                RenderContext::useAllColumn,
                RenderContext::useOnlyColumn,
            )
            val stageKey = PartialStageStore.map(Stage.key())
            val stageIngredientStore = PartialStageStore.map(Stage.ingredients())
            stageIngredientStore.renderEach(idProvider = Ingredient::key) { oneIngredient ->
                div {
                    val ingredientRowParms = IngredientRowFactoryParameterPack(
                        gridSpans   = colSpans,
                        actions     = actions,
                        stageKey    = stageKey.current,
                        ingredient  = oneIngredient,
                        disable     = listOf(true, false, false, false, false)
                    )
                    ingredientRowFactory(storeOf(ingredientRowParms))
                }
            }
        }
    }

    fun buttonAction() {
        // Convert g to % based on the switch setting. If the switch is set to %, copy the qty value to %.
        // If it's set to g, convert the qty value to % based on the total flour in the preferment.
        myLog("Preferment 2: Button action")
        val overallFlour = FormulaStore.current.stages.first { it.stageType == StageType.overall }.totalFlour()
        val prefermentedFlour = PartialStageStore.current.prefermentedFlour * overallFlour
        myLog("Preferment 2: Overall flour = $overallFlour, prefermented flour = $prefermentedFlour")
        val stageIngredientStore = PartialStageStore.map(Stage.ingredients())
        stageIngredientStore.update(stageIngredientStore.current.map { ing ->
            if (ing.usePercent) {
                myLog("Preferment 2: Using percent, qty = ${ing.qty}")
                ing.copy(percent = ing.qty / 100.0, qty = 0.0)
            } else {
                myLog("Preferment 2: Using grams, qty = ${ing.qty}")
                ing.copy(percent = ing.qty / prefermentedFlour, qty = 0.0)
            }
        })
    }

    val parms = storeOf(
        ModalFrameworkParameterPak(
            title = "Amount of each ingredient in the preferment",
            description = "Indicate the amount of each ingredient in this preferment. You can enter percent or grams. If you enter grams, the percent will be calculated for you.",
            panelContent = RenderContext::panelContentAction,
            buttonContent = ::buttonAction,
            buttonLabel = "Go",
        )
    )

    modalFramework(parms)
}
