package utils

import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

fun currentYear(): Int {
    val current = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())
    return current.year
}

/*
 * Returns a function that will only call the block if the delayMillis has passed since the last call.
 * Typical use is like this:
 * val debounceResize = debounce(200) { ...resize code... }
 */
fun debounce(delayMillis: Long, block: () -> Unit): () -> Unit {
    var lastTime = 0L
    return {
        val now = Clock.System.now().toEpochMilliseconds()
        if (now >= lastTime + delayMillis) {
            lastTime = now
            block()
        }
    }
}

/*
 * Prints a message to the console in green text, to easily distinguish it from the rest of the output.
 */
fun myLog(message: String) {
    println("\u001B[32m$message\u001B[0m")
}

fun myLogError(message: String) {
    println("\u001B[31m$message\u001B[0m")
}