package interviews

import database.FormulaStore
import database.Stage
import database.StageType
import dev.fritz2.core.*
import model.Formats
import model.UI
import database.prefermentedFlour
import utils.switchTemplate

fun RenderContext.prefermentInterviewPart1() {
    val number = storeOf(0.0).map(Formats.quantity)
    val tState = storeOf(true)
    val percent = PartialStageStore.map(Stage.prefermentedFlour())

    fun RenderContext.panelContent() {
        div("grid grid-col-1 items-center") {
            div {
                switchTemplate(
                    description = "units",
                    leftLabel   = "%",
                    rightLabel  = "g",
                    tState      = tState,
                    onOff       = false
                )
            }
            div("w-full text-center mt-4") {
                input("w-16 bg-white text-gray-700 px-4 py-2 leading-tight ${UI.hoverColor}") {
                    type("text")
                    value(number.data)
                    changes.values() handledBy number.update
                }
            }
        }
    }

    fun buttonContent() {
        val n = number.current.toDoubleOrNull() ?: 0.0
        if (tState.current) {
            percent.update(n / 100.0)
        } else {
            val overallStage = FormulaStore.current.stages.first { it.stageType == StageType.overall }
            val totalFlour = overallStage.totalFlour()
            percent.update(n / totalFlour)
        }
    }

    val parms = storeOf(
        ModalFrameworkParameterPak(
            title = "Percent of prefermented flour",
            description = "Indicate the amount of prefermented flour in this preferment. You can enter grams or percent.",
            panelContent = RenderContext::panelContent,
            buttonContent = ::buttonContent,
            buttonLabel = "Next",
        )
    )
    modalFramework(parms)
}
