package interviews

import database.*
import dev.fritz2.core.RenderContext
import dev.fritz2.core.invoke
import dev.fritz2.core.storeOf
import utils.myLog

fun RenderContext.soakerInterview1() {

    fun buttonAction() {
        val stageIngredientStore = PartialStageStore.map(Stage.ingredients())
        val newIngredientList = gSelectedIngredients.current.map { it.copy( percent = 1.0 ) }
        stageIngredientStore.update(newIngredientList)
        gSelectedIngredients.update(emptyList())
    }

    val parms = storeOf(
        ModalFrameworkParameterPak(
            title = "Soaker",
            description = "Select the ingredients included in the soaker:",
            panelContent = RenderContext::ingredientSelector,
            buttonContent = ::buttonAction,
            buttonLabel = "Save",
        )
    )
    modalFramework(parms)
}

fun RenderContext.soakerCleanup() {
    val nextSoaker = FormulaStore.map(Formula.nextSoaker())
    val thisCount = nextSoaker.current
    val newStage = PartialStageStore.current.copy(
        name = "Soaker $thisCount",
        stageType = StageType.soaker,
        prefermentedFlour = 1.0,
    )
    nextSoaker.update(thisCount + 1)
    rollUpStageToIngredient(newStage)
    FormulaStore.addStage(newStage)

    StateStore.reset()
    FormulaStore.clearStageRequest()
    PartialStageStore.reset()
}

fun RenderContext.soakerInterview() {
    PartialStageStore.reset()
    StateStore.data.render { s ->  // initial state is managed by the requestStage handler
        when(s) {
            0 -> myLog("soakerInterview state is 0")
            1 -> soakerInterview1()
            2 -> soakerCleanup()
            -1 -> FormulaStore.clearStageRequest()
            else -> {}
        }
    }
}