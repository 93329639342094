package main_panel

import database.*
import dev.fritz2.core.*
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import model.ColumnHeadingFactoryParameterPack
import model.Formats
import model.UI
import utils.intToColSpan
import utils.intToGridCols


@Lenses
data class IngredientRowFactoryParameterPack (
    val gridSpans:          List<Int> = emptyList(),
    val actions:            List<RenderContext.(actionFnParameterPack) -> Unit> = emptyList(),
    val stageKey:           String = "",
    val ingredient:         Store<Ingredient> = storeOf(Ingredient(), job = Job()),
    val disable:            List<Boolean> = emptyList(),
    val ingredientCount:    Int = 0,
    val ingredientIdx:      Int = 0,
    val tabIndex:           Int = 0,
) {
    companion object
}

open class IngredientGridFactoryParameterPack (
    val columnHeadings: ColumnHeadingFactoryParameterPack = ColumnHeadingFactoryParameterPack(),
    val ingredientRow: IngredientRowFactoryParameterPack = IngredientRowFactoryParameterPack(),
)

object OverallGridFormat : IngredientGridFactoryParameterPack(
    columnHeadings = ColumnHeadingFactoryParameterPack(
        gridSpans = listOf(4, 1, 1, 1, 1),
        labels = listOf("Ingredient", "-%", "-Qty", "-Unit", " "),
    ),
    ingredientRow = IngredientRowFactoryParameterPack(
        gridSpans = listOf(4, 1, 1, 1, 1),
        actions = listOf(
            RenderContext::ingredientNameColumn,
            RenderContext::percentColumn,
            RenderContext::qtyColumn,
            RenderContext::unitsColumn,
            RenderContext::ingredientDeleteButton,
        ),
        disable = listOf(false, false, false, false, false),
    ),
)

object MidStageGridFormat : IngredientGridFactoryParameterPack(
    columnHeadings = ColumnHeadingFactoryParameterPack(
        gridSpans = listOf(4, 1, 1, 1, 1),
        labels = listOf("Ingredient", "-%", "-Qty", "-Unit", " ")
    ),
    ingredientRow = IngredientRowFactoryParameterPack(
        gridSpans = listOf(4, 1, 1, 1, 1),
        actions = listOf(
            RenderContext::ingredientNameColumn,
            RenderContext::percentColumn,
            RenderContext::qtyColumn,
            RenderContext::unitsColumn,
            RenderContext::ingredientDeleteButton,
        ),
        disable = listOf(true, false, true, true, false),
    ),
)

object FinalGridFormat : IngredientGridFactoryParameterPack(
    columnHeadings = ColumnHeadingFactoryParameterPack(
        gridSpans = listOf(3, 2, 1),
        labels = listOf("Ingredient", "-Qty", "-Unit")
    ),
    ingredientRow = IngredientRowFactoryParameterPack(
        gridSpans = listOf(3, 2, 1),
        actions = listOf(
            RenderContext::ingredientNameColumn,
            RenderContext::qtyColumn,
            RenderContext::unitsColumn,
        ),
        disable = listOf(true, true, true),
    ),
)


fun RenderContext.columnHeadingFramework( parms: Store<ColumnHeadingFactoryParameterPack> )
{
    parms.data.render { p ->
        val gridCols = intToGridCols(p.gridSpans.sum())
        div("w-full h-full isolate grid $gridCols gap-0 mt-6 mb-2") {
            for (i in p.labels.indices) {
                val centerMe = p.labels[i].first() == '*'
                val rightMe = p.labels[i].first() == '-'
                val label = if (centerMe || rightMe) p.labels[i].drop(1) else p.labels[i]
                val labelFormat: String = "flex items-center pl-0 uppercase text-xs font-semibold text-slate-500/70" + if (centerMe) " justify-center" else if (rightMe) " justify-end" else ""
                div(intToColSpan(p.gridSpans[i])) {
                    val tip = p.tips.getOrNull(i)
                    if (tip != null) {
                        label(labelFormat) {
                            `for`("grid-col-$i")
                            +label
                        }.tooltip(UI.tooltipStyle){
                            +tip
                            placement = PlacementValues.top
                            arrow()
                        }
                    } else {
                        label(labelFormat) {
                            `for`("grid-col-$i")
                            +label
                        }
                    }
                }
            }
        }
    }
}


/*
 * Modularized ingredient row. Pass it any list of grid spans and a list of action functions, and this will
 * render a row of ingredients. The action functions should provide the actual cell content, along with field
 * update handlers.
 */
fun RenderContext.ingredientRowFactory(parms: Store<IngredientRowFactoryParameterPack>) {
    parms.data.render { p ->
        val gridCols = intToGridCols(p.gridSpans.sum())
        // myLog("ingredientRowFactory: ingredientIdx = ${p.ingredientIdx} tabIndex = ${p.tabIndex}")
        div("w-full border-t border-indigo-200") {
            div("grid $gridCols gap-0 px-0"){
                for (i in p.actions.indices) {
                    val colSpan = intToColSpan(p.gridSpans[i])
                    val t = tabIndexCalc(
                        offset          = p.tabIndex,
                        ingredientCount = p.ingredientCount,
                        ingredientIdx   = p.ingredientIdx,
                        col             = i,
                    )
                    val afp = actionFnParameterPack(
                        stageKey        = p.stageKey,
                        ingredient      = p.ingredient,
                        gridCol         = i,
                        disable         = p.disable[i],
                        ingredientCount = parms.current.ingredientCount,
                        ingredientIdx   = parms.current.ingredientIdx,
                        tabIndex        = t,
                    )
                    div("$colSpan h-[1.625rem]") {
                        p.actions[i](afp)
                    }
                }
            }
        }
    }
}

data class actionFnParameterPack(
    val stageKey:           String,
    val ingredient:         Store<Ingredient>,
    val gridCol:            Int,
    val disable:            Boolean,
    val ingredientCount:    Int,
    val ingredientIdx:      Int,
    val tabIndex:           Int,
)

private val cellFormat = "bg-transparent px-0 pb-0 w-full h-full text-xs font-medium"

/*
 * ingredientCount (1..n)
 * ingredeintIdx (0..n-1)
 * col (0..n-1)
 */
fun tabIndexCalc(offset: Int, ingredientCount: Int, ingredientIdx: Int, col: Int, caller:String = "") : Int {
    val result = offset + col * ingredientCount + ingredientIdx + 1 // tabIndex should be >= 1, as 0 has a different meaning
    // myLog("tabIndexCalc $caller $ingredientCount ingredients, ing#$ingredientIdx, col $col = $result")
    return result
}

fun RenderContext.ingredientNameColumn(p: actionFnParameterPack) {
    val nameStore = p.ingredient.map(Ingredient.name())
    val keyStore = p.ingredient.map(Ingredient.key())
    val thisStage = FormulaStore.current.getStage(p.stageKey)
    val stageType = thisStage.stageType

    // The IngredientDatabaseStore needs to be rendered here so that the select list is updated when the database is updated.
    IngredientDatabaseStore.data.render { db ->
        val dbList = db.ingredients
        select("$cellFormat appearance-none" + if (!p.disable) " ${UI.hoverColor}" else "") {
            name("ing-choice")
            id("grid-col-${p.gridCol}")
            value(nameStore.data)
            // value(p.tabIndex.toString())
            tabIndex(p.tabIndex)
            disabled(p.disable)

            // if this is the Overall stage, filter out RollUp ingredients
            val dbFiltered = if (stageType == StageType.overall) dbList.filter{ !it.isRollup } else dbList
            dbFiltered.forEach { iFromDB ->
                option {
                    +iFromDB.name
                    selected(nameStore.current == iFromDB.name)
                }
            }
            changes.selectedText().map {
                Triple(
                    p.stageKey,
                    keyStore.current,
                    domNode.value
                )
            } handledBy FormulaStore.replaceIngredient
        }
    }
}

fun RenderContext.percentColumn(p: actionFnParameterPack) {
    val percentStore = p.ingredient.map(Ingredient.percent() + Formats.percent)
    input(cellFormat + " text-right" + if (!p.disable) " ${UI.hoverColor}" else "") {
        type("text")
        id("grid-col-${p.gridCol}")
        disabled(p.disable)
        value(percentStore.data)
        tabIndex(p.tabIndex)

        changes.values() handledBy percentStore.update
    }
}

fun RenderContext.qtyColumn(p: actionFnParameterPack) {
    val qtyStore = p.ingredient.map(Ingredient.qty() + Formats.quantity)
    input(cellFormat + " text-right" + if (!p.disable) " ${UI.hoverColor}" else "") {
        type("text")
        id("grid-col-${p.gridCol}")
        disabled(p.disable)
        value(qtyStore.data)
        tabIndex(p.tabIndex)

        changes.values() handledBy qtyStore.update
    }
}

fun RenderContext.unitsColumn(p: actionFnParameterPack) {
    val unitsStore = p.ingredient.map(Ingredient.units() + Formats.units)
    select("$cellFormat text-right appearance-none" + if (!p.disable) " ${UI.hoverColor}" else "" ) {
        name("unit-choice")
        id("grid-col-${p.gridCol}")
        disabled(p.disable)
        value(unitsStore.data.asString())
        tabIndex(p.tabIndex)

        for (u in Units.entries) {
            option {
                val us = u.toString()
                val usl = u.toString().lowercase()
                +usl
                selected(us == unitsStore.current)
            }
        }
        changes.selectedText() handledBy unitsStore.update
    }
}

fun RenderContext.ingredientDeleteButton(p: actionFnParameterPack) {
    div("w-full h-full grid place-content-center") {
        button("text-slate-200 hover:text-red-500 icon-[grommet-icons--trash] text-lg") {
            tabIndex(p.tabIndex)
            clicks.map {
                Pair(p.stageKey, p.ingredient.current.key)
            } handledBy FormulaStore.deleteIngredient
        }
    }
}

fun RenderContext.ingredientTable(parentStage: Store<Stage>, parms: IngredientGridFactoryParameterPack, tabIndex: Int) {
    val ingredientsStore = parentStage.map(Stage.ingredients())
    val headerStore = storeOf(parms.columnHeadings)
    val stageKey = parentStage.map(Stage.key())
    var count = 0

    columnHeadingFramework(headerStore)

    ingredientsStore.renderEach(idProvider = Ingredient::key) { ingredientStore ->
        div {
            val ingredientRowParms = storeOf(
                IngredientRowFactoryParameterPack(
                    gridSpans       = parms.ingredientRow.gridSpans,
                    actions         = parms.ingredientRow.actions,
                    stageKey        = stageKey.current,
                    ingredient      = ingredientStore,
                    disable         = parms.ingredientRow.disable,
                    ingredientCount = parentStage.current.ingredients.count(),
                    ingredientIdx   = count++,
                    tabIndex        = tabIndex,
                )
            )

            ingredientRowFactory(ingredientRowParms)
        }

    }
}

