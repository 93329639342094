// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.Int
import kotlin.String

public fun NewCustomerData.Companion.first_name(): Lens<NewCustomerData, String> = lensOf(
    "first_name",
    { it.first_name },
    { p, v -> p.copy(first_name = v)}
  )

public fun <PARENT> Lens<PARENT, NewCustomerData>.first_name(): Lens<PARENT, String> = this +
    NewCustomerData.first_name()

public fun NewCustomerData.Companion.last_name(): Lens<NewCustomerData, String> = lensOf(
    "last_name",
    { it.last_name },
    { p, v -> p.copy(last_name = v)}
  )

public fun <PARENT> Lens<PARENT, NewCustomerData>.last_name(): Lens<PARENT, String> = this +
    NewCustomerData.last_name()

public fun NewCustomerData.Companion.years_baking(): Lens<NewCustomerData, Int> = lensOf(
    "years_baking",
    { it.years_baking },
    { p, v -> p.copy(years_baking = v)}
  )

public fun <PARENT> Lens<PARENT, NewCustomerData>.years_baking(): Lens<PARENT, Int> = this +
    NewCustomerData.years_baking()

public fun NewCustomerData.Companion.baking_level(): Lens<NewCustomerData, BakingLevel> = lensOf(
    "baking_level",
    { it.baking_level },
    { p, v -> p.copy(baking_level = v)}
  )

public fun <PARENT> Lens<PARENT, NewCustomerData>.baking_level(): Lens<PARENT, BakingLevel> = this +
    NewCustomerData.baking_level()

public fun NewCustomerData.Companion.city(): Lens<NewCustomerData, String> = lensOf(
    "city",
    { it.city },
    { p, v -> p.copy(city = v)}
  )

public fun <PARENT> Lens<PARENT, NewCustomerData>.city(): Lens<PARENT, String> = this +
    NewCustomerData.city()

public fun NewCustomerData.Companion.access_code(): Lens<NewCustomerData, String> = lensOf(
    "access_code",
    { it.access_code },
    { p, v -> p.copy(access_code = v)}
  )

public fun <PARENT> Lens<PARENT, NewCustomerData>.access_code(): Lens<PARENT, String> = this +
    NewCustomerData.access_code()
