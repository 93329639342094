package database

import io.github.jan.supabase.auth.user.UserInfo
import io.github.jan.supabase.postgrest.from
import kotlinx.serialization.json.int
import kotlinx.serialization.json.jsonPrimitive
import model.AuthLevel
import model.BakingLevel
import model.Customer
import session.HttpSession
import utils.currentYear
import utils.myLog

// The currently logged in user
object CustomerSession {
    private var c: Customer = Customer()
    private var loggedIn: Boolean = false

    fun logout() {
        c = Customer()
        loggedIn = false

    }

    fun get(): Customer {
        return c
    }

    fun isLoggedIn(): Boolean {
        return ::isLoggedIn as Boolean
    }

    suspend fun load(user: UserInfo) {
        val client = HttpSession.fetchClient()

        myLog("loadCustomer: user.id = ${user.id}")

        // Query the database for a customer record with the user's ID
        val customerResponse = client
            .from("customers")
            .select() { // Select all columns
                filter { eq("user_id", user.id) }
            }
            .decodeSingleOrNull<Customer>()

        // If no customer record is found, create a new one.
        // Use the metadata captured from the sign-up form to populate the new record.
        if (customerResponse == null) {
            myLog("loadCustomer: Customer not found, creating new record")
            val metadata = user.userMetadata
            val yearsBaking = metadata?.get("years_baking")?.jsonPrimitive?.int ?: 0
            val newCustomer = Customer(
                user_id     = user.id,
                email       = user.email.takeIf { it?.isNotEmpty() == true }
                                ?: metadata?.get("email")?.jsonPrimitive?.content
                                ?: "",
                auth_level  = AuthLevel.Free,
                first_name  = metadata?.get("first_name")?.jsonPrimitive?.content ?: "",
                last_name   = metadata?.get("last_name")?.jsonPrimitive?.content ?: "",
                start_year  = currentYear() - yearsBaking,
                baking_level= metadata?.get("baking_level")
                                ?.jsonPrimitive?.content?.let { BakingLevel.valueOf(it) } ?: BakingLevel.beginner,
                city        = metadata?.get("city")?.jsonPrimitive?.content ?: ""
            )

            client
                .from("customers")
                .insert(newCustomer)

            // The database creates the id, so we have to query the database to get it.
            c = client
                .from("customers")
                .select() {
                    filter { eq("user_id", user.id) }
                }
                .decodeSingleOrNull<Customer>() ?: newCustomer
        } else {
            myLog("loadCustomer: Customer found")
            c = customerResponse
        }
        myLog(c.toString())
        loggedIn = true
    }
}