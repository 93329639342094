package model
import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import dev.fritz2.validation.ValidationMessage
import dev.fritz2.validation.validation
import kotlinx.serialization.Serializable

enum class BfSeverity {
    Info,
    Warning,
    Error,
}

data class SignUpMessage(
    override val path: String,
    val severity: BfSeverity,
    val text: String,
): ValidationMessage {
    override val isError: Boolean = severity > BfSeverity.Warning
}

fun String.isValidEmail(): Boolean {
    val emailRegex = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}$".toRegex()
    return emailRegex.matches(this)
}

fun String.isValidPassword(): Boolean {
    val passwordRegex = "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&.,+=?!*)(_|~-])(?=\\S+$).{6,}$".toRegex()
    return passwordRegex.matches(this)
}

@Lenses
@Serializable
data class UserLoginParams (
    val email: String,
    val password: String,
    val captchaToken: String,
) {
    companion object {
        val myValidator: Validation<UserLoginParams, Unit, SignUpMessage> =
            // Note that validation is an inline function that takes a lambda with receiver
            validation<UserLoginParams, SignUpMessage> { inspector ->
                val email = inspector.map(UserLoginParams.email())
                if (email.data.trim().isBlank()) {
                    add(SignUpMessage(email.path, BfSeverity.Error, "Email is required"))
                } else if (!email.data.isValidEmail()) {
                    add(SignUpMessage(email.path, BfSeverity.Error, "Not a valid email address"))
                }

                val password = inspector.map(UserLoginParams.password())
                if (password.data.trim().isBlank()) {
                    add(SignUpMessage(password.path, BfSeverity.Error, "Password is required"))
                } else if (!password.data.isValidPassword()) {
                    add(SignUpMessage(password.path, BfSeverity.Error,
                        "Password must be at least 6 characters, with at least one number, one uppercase letter, one lowercase letter, and one special character"
                       )
                    )
                }
            }

    }
}

@Serializable
data class ServerLoginResponse(
    val success: Boolean = false,
    val message: String,
    // val data: User? = null
)

