package session

import kotlinx.browser.document

actual object CookieHandler {
    actual fun getCookie(name: String): String? {
        val cookies = document.cookie.split("; ")
        for (cookie in cookies) {
            val parts = cookie.split("=")
            if (parts[0] == name) {
                return parts[1]
            }
        }
        return null
    }

    actual fun setCookie(name: String, value: String, days: Int) {
        val date = js("new Date()")
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
        val expires = "expires=${date.toUTCString()}"
        document.cookie = "$name=$value; $expires; path=/; SameSite=Strict; Secure"
    }
}