package auth

import io.github.jan.supabase.auth.auth
import session.CookieHandler
import session.HttpSession
import utils.myLog

suspend fun logoutUser(): Boolean {
    val client = HttpSession.fetchClient()

    try {
        client.auth.signOut()
        CookieHandler.setCookie(HttpSession.AUTH_COOKIE_NAME, "", -1)
        myLog("Sign out successful")
        return true
    } catch (e: Exception) {
        myLog("Sign out exception: $e")
        return false
    }
}


