package database

import dev.fritz2.core.Id
import dev.fritz2.core.Lenses
import kotlinx.serialization.Serializable

enum class StageType {
    overall,
    preferment,
    soaker,
    final
}

@Lenses
@Serializable
data class Stage(
    val key:                    String = Id.next(),
    val name:                   String = "",
    val stageType:              StageType = StageType.overall,
    val prefermentedFlour:      Double = 0.0,
    val prefermentUnitType:     Boolean = true, // true = percent, false = g
    val ingredients:            List<Ingredient> = emptyList(),
    val process:                String = "",
)
{
    companion object { }

    // Throws an exception if the ingredientKey is not found
    fun getIngredient(ingredientId: String): Ingredient {
        return this.ingredients.first { it.key == ingredientId }
    }

    fun findIngredientIdx (ingredientName: String): Int {
        return this.ingredients.indexOfFirst { it.name == ingredientName }
    }

    fun updateIngredient(newIngredient: Ingredient): Stage {
        val newIngredients = this.ingredients.map { ing ->
            if (ing.key == newIngredient.key) newIngredient else ing
        }
        return this.copy(ingredients = newIngredients)
    }

    fun updateIngredients(newIngredients: List<Ingredient>): Stage {
        return this.copy(ingredients = newIngredients)
    }

    fun deleteIngredient(ingredientKey: String): Stage {
        val newIngredients = this.ingredients.filter { it.key != ingredientKey }
        return this.copy(ingredients = newIngredients)
    }

    fun totalFlour(): Double {
        return this.ingredients.filter { it.isFlour }.sumOf { it.qty }
    }
}

