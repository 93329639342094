// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package database

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.Boolean
import kotlin.Double
import kotlin.Int
import kotlin.String
import kotlin.collections.List

public fun Formula.Companion.title(): Lens<Formula, String> = lensOf(
    "title",
    { it.title },
    { p, v -> p.copy(title = v)}
  )

public fun <PARENT> Lens<PARENT, Formula>.title(): Lens<PARENT, String> = this + Formula.title()

public fun Formula.Companion.author(): Lens<Formula, String> = lensOf(
    "author",
    { it.author },
    { p, v -> p.copy(author = v)}
  )

public fun <PARENT> Lens<PARENT, Formula>.author(): Lens<PARENT, String> = this + Formula.author()

public fun Formula.Companion.basedOn(): Lens<Formula, String> = lensOf(
    "basedOn",
    { it.basedOn },
    { p, v -> p.copy(basedOn = v)}
  )

public fun <PARENT> Lens<PARENT, Formula>.basedOn(): Lens<PARENT, String> = this + Formula.basedOn()

public fun Formula.Companion.nPieces(): Lens<Formula, Double> = lensOf(
    "nPieces",
    { it.nPieces },
    { p, v -> p.copy(nPieces = v)}
  )

public fun <PARENT> Lens<PARENT, Formula>.nPieces(): Lens<PARENT, Double> = this + Formula.nPieces()

public fun Formula.Companion.pieceSize(): Lens<Formula, Double> = lensOf(
    "pieceSize",
    { it.pieceSize },
    { p, v -> p.copy(pieceSize = v)}
  )

public fun <PARENT> Lens<PARENT, Formula>.pieceSize(): Lens<PARENT, Double> = this +
    Formula.pieceSize()

public fun Formula.Companion.totalSize(): Lens<Formula, Double> = lensOf(
    "totalSize",
    { it.totalSize },
    { p, v -> p.copy(totalSize = v)}
  )

public fun <PARENT> Lens<PARENT, Formula>.totalSize(): Lens<PARENT, Double> = this +
    Formula.totalSize()

public fun Formula.Companion.stages(): Lens<Formula, List<Stage>> = lensOf(
    "stages",
    { it.stages },
    { p, v -> p.copy(stages = v)}
  )

public fun <PARENT> Lens<PARENT, Formula>.stages(): Lens<PARENT, List<Stage>> = this +
    Formula.stages()

public fun Formula.Companion.newStageRequest(): Lens<Formula, Boolean> = lensOf(
    "newStageRequest",
    { it.newStageRequest },
    { p, v -> p.copy(newStageRequest = v)}
  )

public fun <PARENT> Lens<PARENT, Formula>.newStageRequest(): Lens<PARENT, Boolean> = this +
    Formula.newStageRequest()

public fun Formula.Companion.newStageType(): Lens<Formula, StageType> = lensOf(
    "newStageType",
    { it.newStageType },
    { p, v -> p.copy(newStageType = v)}
  )

public fun <PARENT> Lens<PARENT, Formula>.newStageType(): Lens<PARENT, StageType> = this +
    Formula.newStageType()

public fun Formula.Companion.nextPreferment(): Lens<Formula, Int> = lensOf(
    "nextPreferment",
    { it.nextPreferment },
    { p, v -> p.copy(nextPreferment = v)}
  )

public fun <PARENT> Lens<PARENT, Formula>.nextPreferment(): Lens<PARENT, Int> = this +
    Formula.nextPreferment()

public fun Formula.Companion.nextSoaker(): Lens<Formula, Int> = lensOf(
    "nextSoaker",
    { it.nextSoaker },
    { p, v -> p.copy(nextSoaker = v)}
  )

public fun <PARENT> Lens<PARENT, Formula>.nextSoaker(): Lens<PARENT, Int> = this +
    Formula.nextSoaker()
