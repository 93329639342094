package auth

import database.CustomerSession
import io.github.jan.supabase.auth.auth
import io.github.jan.supabase.auth.providers.builtin.Email
import io.github.jan.supabase.auth.status.SessionStatus
import io.github.jan.supabase.auth.user.UserSession
import kotlinx.coroutines.flow.first
import kotlinx.serialization.json.Json
import model.ServerLoginResponse
import model.UserLoginParams
import session.CookieHandler
import session.HttpSession
import utils.myLog

suspend fun loginUser(userParams: UserLoginParams): ServerLoginResponse {
    val client = HttpSession.fetchClient()
    var message = "Login successful"

    myLog("Sign in ${userParams.email}")
    try {
        client.auth.signInWith(Email) {
            email = userParams.email
            password = userParams.password
            captchaToken = userParams.captchaToken
        }
        val session = client.auth.currentSessionOrNull()
        if (session != null) {
            val sessionJson = Json.encodeToString(UserSession.serializer(), session)
            CookieHandler.setCookie(HttpSession.AUTH_COOKIE_NAME, sessionJson, 14)
            return ServerLoginResponse(true, message)
        } else {
            message = "Login failed"
            return ServerLoginResponse(false, message)
        }
    } catch (e: Exception) {
        val msg = e.message
        val cause = e.cause

        myLog("Sign in exception: $e")

        // Supabase returns a message with the full URL, token, etc.
        // Convert this to a more user-friendly message.
        message = if (msg != null) {
            if (msg.contains("invalid_grant")) {
                // pull out the actual error from between the parenthesis
                val start = msg.indexOf("(")
                val end = msg.indexOf(")")
                msg.substring(start + 1, end)
            } else if (msg.contains("captcha")) {
                "Captcha verification error. Please reload the page and try again."
            } else {
                msg
            }
        } else {
            "Login failed"
        }
        return ServerLoginResponse(false, message)
    }
}


suspend fun welcomeUser(): ServerLoginResponse {
    val client = HttpSession.fetchClient()
    val user = client.auth.currentUserOrNull()
    return if (user != null) {
        CustomerSession.load(user)
        val customer = CustomerSession.get()
        ServerLoginResponse(true, "Welcome back, ${customer.first_name}")
    } else {
        ServerLoginResponse(false, "You are not logged in")
    }
}

suspend fun checkAndRestoreSession(): Boolean {
    val sessionJson = CookieHandler.getCookie(HttpSession.AUTH_COOKIE_NAME)
    val savedSession: UserSession? = sessionJson?.let { s-> Json.decodeFromString(UserSession.serializer(), s) }

    if (savedSession != null) {
        try {
            val client = HttpSession.fetchClient()
            client.auth.sessionManager.saveSession(savedSession)

            // Wait for the session to be restored
            val sessionStatus = client.auth.sessionStatus.first()
            val result = sessionStatus is SessionStatus.Authenticated
            myLog("Restored session status: ${if (!result) "not " else ""} authenticated")
            return result
        } catch (e: Exception) {
            myLog("Failed to restore session: ${e.message}")
            // Clear the invalid cookie
            CookieHandler.setCookie(HttpSession.AUTH_COOKIE_NAME, "", 0)
        }
    }
    return false
}
