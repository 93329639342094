package interviews

import database.CustomerSession
import database.Ingredient
import database.IngredientDatabaseStore
import dev.fritz2.core.*
import model.UI

fun RenderContext.specialIngredientInterviewUnique() {
    val newName = storeOf("")
    val newIsFlour = storeOf(false)

    fun RenderContext.uniquePanelContent() {
        div("w-full text-center mt-4") {
            p("font-semibold") { +"New ingredient name:"}
            input("w-48 bg-white text-gray-700 px-4 pt-1 pb-2 mt-4 leading-tight ${UI.hoverColor}") {
                type("text")
                value(newName.data)
                changes.values() handledBy newName.update
            }
        }

        div("w-full text-center mt-4") {
            p("font-semibold") { +"Is this a flour ingredient?"}
            input("w-48 bg-white text-gray-700 px-4 pt-1 pb-2 mt-2 h-[30px] w-[30px] ${UI.hoverColor}") {
                type("checkbox")
                value(newIsFlour.data.asString())
                changes.states() handledBy newIsFlour.update
            }
        }
    }

    fun buttonAction() {
        val newIngredient = Ingredient(
            name = newName.current,
            isFlour = newIsFlour.current,
            saveThis = true,
            ownerId = CustomerSession.get().id
        )
        IngredientDatabaseStore.addIngredient(newIngredient)
    }

    val parms = storeOf(
        ModalFrameworkParameterPak(
            title = "New Ingredient",
            description = "Enter the name of the new ingredient and indicate whether it is a flour ingredient.",
            panelContent = RenderContext::uniquePanelContent,
            buttonContent = ::buttonAction,
            buttonLabel = "Save",
        )
    )
    modalFramework(parms)
}
