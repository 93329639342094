package interviews

import database.*
import dev.fritz2.core.RenderContext
import dev.fritz2.core.invoke
import utils.myLog

fun prefermentCleanup() {
    // Fill in the missing parts of the preferment and add it to the formula
    val nextPreferment = FormulaStore.map(Formula.nextPreferment())
    val newStage = PartialStageStore.current.copy(
        name = "Preferment ${nextPreferment.current}",
        stageType = StageType.preferment,
    )
    nextPreferment.update(nextPreferment.current + 1)

    // Now roll up this mid stage into a new ingredient.
    val rollup = rollUpStageToIngredient(newStage)
    myLog("prefermentCleanup: Adding rollup ingredient ${rollup.name} to the ingredient database")
    val rollups = IngredientDatabaseStore.current.ingredients.filter { it.isRollup }
    myLog("prefermentCleanup: State after adding ingredient: ${rollups.map { it.name }}")

    FormulaStore.addStage(newStage)
    FormulaStore.clearStageRequest()
    StateStore.reset()
    PartialStageStore.reset()
    FormulaStore.calcDispatch("g") // recalc(false)
}

fun RenderContext.prefermentInterview() {
    PartialStageStore.reset()
    StateStore.data.render { s ->
        when(s) {
            0 -> myLog("prefermentInterview state is 0")
            1 -> prefermentInterviewPart1()
            2 -> prefermentInterviewPart2()
            3 -> prefermentInterviewPart3()
            4 -> prefermentCleanup()
            -1 -> FormulaStore.clearStageRequest()
            else -> {}
        }
    }
}
